import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AddContactOptions } from "../models/addContact.getResponse.model";
import { SendEmail } from "../models/sendEmail.model";

@Injectable({ providedIn: 'root' })
export class GetResponseService {

  constructor(private http: HttpClient) {}

  /**
   * Adds contact to GetResponse
   * @param data Data to send to GetResponse, Contact Data
   * @returns Promise<boolean>
   */
  addContact(data: AddContactOptions): Promise < any > {
    return this.http.post < any > (`${environment.apiUrl}/GetResponse/AddContact`, data)
      .toPromise()
  }

  /**
   * Adds contact to GetResponse
   * @param data Data to send to GetResponse, Contact Data
   * @returns Promise<boolean>
   */
  addContactV2(data: AddContactOptions): Promise < any > {
    return this.http.post < any > (`${environment.apiUrl}/GetResponse/AddContactV2`, data)
      .toPromise()
  }

  /**
   * 
   * @param name Name of the sender
   * @param email Email of the sender
   * @param phone Phone of the sender
   * @param message Message written by the sender
   * @returns Promise<any>
   */
  sendEmail(data: SendEmail): Promise < any > {
    return this.http.post < any > (`${environment.apiUrl}/GetResponse/SendEmail`, data)
      .toPromise()
  }

  // Gets client IPV4 address
  getClientIp(): Promise < string > {
    return this.http.get < string > ("https://api.ipify.org/?format=json")
      .toPromise()
  }
}