import { Component, OnInit, Renderer2 } from '@angular/core';
import { SEOService } from "../../../helpers/services/seo.service";
import { ActivatedRoute } from "@angular/router";
import { FacebookPixelsService } from "../../../helpers/services/facebookPixels.services";

@Component({
  selector: 'app-generic-thank-you',
  templateUrl: './generic-thank-you.component.html',
  styleUrls: ['./generic-thank-you.component.scss']
})
export class GenericThankYouComponent implements OnInit {

  courseName: string = ''

  constructor(
    private renderer2: Renderer2,
    private fbService: FacebookPixelsService,
    private route: ActivatedRoute,
    private seo: SEOService) {
    this.route.params.subscribe(params => {
      this.courseName = params['courseId'].replace(/-/g, ' ')
    })

    this.seo.updateTitle(`Thank You ${this.courseName} | Top-Trading Academy`)
    this.seo.updateDescription(`Grazie per l'iscrizione a ${this.courseName}`)
  }

  async ngOnInit() {
    await this.fbService.injectConversionThankYouCourse(this.renderer2)
  }
}
