import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Gtag } from 'angular-gtag';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { fade } from 'src/app/helpers/animations/fade.animation';
import { SendEmail } from 'src/app/helpers/models/sendEmail.model';
import { FacebookPixelsService } from 'src/app/helpers/services/facebookPixels.services';
import { GetResponseService } from 'src/app/helpers/services/getResponse.service';
import { WebinarManager } from '../../../helpers/services/webinarManager.service';
import { environment } from '../../../../environments/environment';
import { SEOService } from '../../../helpers/services/seo.service';

var that: any;
const sendEmailValues: SendEmail = {
  name: '',
  email: '',
  phone: '',
  message: '',
  course: ''
}

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
  animations: [ fade ]
})
export class ThankYouComponent implements OnInit, OnDestroy {

  @ViewChild("paypalRef", { static: false })
  private paypalRef: ElementRef

  discordInviteUrl: string = environment.DiscordInviteLink

  shouldDisplayAllInclusiveVideo: boolean = false;
  shouldShowPurchaseButton: boolean = false;
  isGeneralNoDate: boolean = false;
  isNoDateSmartVolume: boolean = false;
  isNoDateAllInclusive: boolean = false;
  isVolumasterVideo: boolean = false;
  loading: boolean = false;
  request: SendEmail = sendEmailValues
  isEmailValid: boolean = true;
  isPhoneValid: boolean = true;
  isNameValid: boolean = true;

  shouldenablePaypal = new BehaviorSubject < boolean > (false);
  shouldenablePaypal$ = this.shouldenablePaypal.asObservable();
  shouldEnablePaypalSubscription: Subscription

  productPrice: number = 0;
  // numero in millisecondi prima che vengano mostrati i bottoni per il pagamento
  productTimeout: number = 0;

  leadName: string = ''
  leadEmail: string = ''
  leadPhone: string = ''

  phone: string = ''
  courseName: string = ''
  productName: string = ''

  productStripeUrl: string = ''

  constructor(
    private router: Router,
    private renderer2: Renderer2,
    private facebookPixelsService: FacebookPixelsService,
    private toastr: ToastrService,
    private webinarManager: WebinarManager,
    private getResponse: GetResponseService,
    private gtag: Gtag,
    private seo: SEOService,
    private route: ActivatedRoute) {
    that = this;
    this.seo.updateTitle("Grazie per l'iscrizione")
    this.route.params.subscribe(params => {
      this.courseName = params['courseId']
      // check if the param comes from a nodate component
      if (this.courseName == 'smart-volume-nodate') {
        this.productStripeUrl = environment.smartVolumeStripePrice
        this.productTimeout = 1000
        this.shouldenablePaypal.next(true)
        this.productPrice = 297
        this.productName = 'smart-volume'
        this.isGeneralNoDate = true;
        this.isNoDateSmartVolume = true;
      }
      if (this.courseName == 'all-inclusive-nodate') {
        this.productStripeUrl = environment.allInclusiveStripePrice
        this.productTimeout = 1000
        this.shouldenablePaypal.next(true)
        this.productPrice = 1347
        this.productName = 'all-inclusive'
        this.isGeneralNoDate = true;
        this.isNoDateAllInclusive = true;
      }
      if (this.courseName == 'volumaster-video') {
        this.isGeneralNoDate = true;
        this.isVolumasterVideo = true
      }
      // check if it should display video (only for standard (!= nodate) components)
      if (params['courseId'] == 'all-inclusive' || params['courseId'] == 'all-inclusive-corso') {
        this.shouldDisplayAllInclusiveVideo = true
        this.isGeneralNoDate = false;
      }
    })

    this.route.queryParams.subscribe(params => {
      this.leadName = params['name']
      this.leadEmail = params['email']
      this.leadPhone = params['phone']
    })
  }

  ngOnInit() {
    this.facebookPixelsService.injectConversionThankYouCourse(this.renderer2)
    this.scrollToTop()
    this.shouldEnablePaypalSubscription = this.shouldenablePaypal$.subscribe(shouldIDoIt => {
      if (shouldIDoIt) {
        setTimeout(() => {
          this.shouldShowPurchaseButton = true
          this.renderPaypal()
        }, this.productTimeout);
      }
    })
    this.gtag.event('conversion', {
      method: 'send_to',
      value: 'AW-661998525/1JeXCOLzmesBEL2X1bsC'
    })
  }

  redirectToCheckout(queryParams: any) {
    function encodeQueryData(data) {
      const ret = []
      for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      return ret.join('&');
    }
    const querystring = encodeQueryData(queryParams)
    window.location.href = environment.clientUrl + `/shop/checkout/success-noreg/${this.productName}?` + querystring
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  async onSubmit() {
    if (this.request.name != '' &&
      this.request.email != '' &&
      this.request.phone != '' &&
      this.request.message != '') {
      this.request.course = await this.polishCourseName(this.courseName)
      this.loading = true;
      this.getResponse.sendEmail(this.request)
        .then(() => {
          this.loading = false;
          this.toastr.success("Messaggio inviato con successo")
          this.resetEmail()
        })
    } else {
      this.toastr.warning("Tutti i campi sono obbligatori", "Attenzione")
    }
  }

  async polishCourseName(corso: string) {
    function capitalizeTheFirstLetterOfEachWord(words) {
      var separateWord = words.toLowerCase().split('-');
      for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
      }
      return separateWord.join(' ');
    }
    return capitalizeTheFirstLetterOfEachWord(corso)
  }

  resetEmail() {
    this.request.name = '',
    this.request.email = '',
    this.request.phone = '',
    this.request.message = '',
    this.request.course = ''
  }

  validateEmail = (email: string) => this.isEmailValid = this.webinarManager.validateEmail(email)

  validatePhone(phone: string) {
    let phoneValidator = this.webinarManager.validatePhone(phone)
    this.isPhoneValid = phoneValidator.isPhoneValid
    this.phone = phoneValidator.correctedPhone
  }

  async renderPaypal() {
    window.paypal.Buttons({
      style: {
        layout: 'horizontal',
        color: 'black',
        shape: 'pill',
        height: 50,
      },

      onInit: function(data, actions) {
        if (that.shouldenablePaypal == false)
          actions.disable()
        actions.enable()
      },

      async createOrder() {
        return fetch(`${environment.apiUrl}/PayPal/CreateOrderNoReg`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(that.productPrice)
        }).then(function(res) {
          return res.json();
        }).then(function(orderData) {
          that.paypalOrderId = orderData.id
          return orderData.id;
        })
      },

      onApprove: async function(data, actions) {
        return fetch(`${environment.apiUrl}/PayPal/CaptureOrder`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(that.paypalOrderId)
        }).then(function(res) {
          return res.json();
        }).then(function(orderData) {
          var errorDetail = Array.isArray(orderData.details) && orderData.details[0];

          if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
            that.toastr.error('Il tuo metodo di pagamento è stato respinto', 'Pagamento respinto')
            return actions.restart(); // Recoverable state, per:
            // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
          }

          if (errorDetail) {
            // var msg = 'Sorry, your transaction could not be processed.';
            // if (errorDetail.description) msg += '\n\n' + errorDetail.description;
            // if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
            return that.toastr.error(`Il tuo pagamento non è stato processato correttamente.`, 'Errore pagamento'); // Show a failure message (try to avoid alerts in production environments)
          }

          // Successful capture! For demo purposes:
          var transaction = orderData.purchase_units[0].payments.captures[0];
          let navigationExtras: NavigationExtras = {
            queryParams: {
              'isPaypal': 'paypal',
              'orderID': that.paypalOrderId,
              'tx_id': transaction.id,
              'tx_status': transaction.status,
              'amount': transaction.amount.value,
              'productName': that.productName,
              'userName': that.leadName,
              'userEmail': that.leadEmail,
              'userPhone': that.leadPhone
            }
          }
          that.redirectToCheckout(navigationExtras.queryParams)
        })
      }
    }).render(this.paypalRef.nativeElement)
  }

  ngOnDestroy(): void {
    this.shouldEnablePaypalSubscription.unsubscribe()
  }
}