import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-lara-assistente',
  templateUrl: './lara-assistente.component.html',
  styleUrls: ['./lara-assistente.component.scss'],
})

export class LaraAssistenteComponent implements OnInit {
  
  constructor() { }

  public ngOnInit(): void {
  } 

  async redirectToHome() {
    window.location.href = environment.clientUrl
  }
}
