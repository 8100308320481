import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebinarResponse } from '../../..//helpers/models/webinarResponse.model';
import { FacebookPixelsService } from '../../..//helpers/services/facebookPixels.services';
import { GetResponseService } from '../../..//helpers/services/getResponse.service';
import { LeadsService } from '../../..//helpers/services/leads.service';
import { SEOService } from '../../..//helpers/services/seo.service';
import { WebinarManager } from '../../..//helpers/services/webinarManager.service';
import { environment } from '../../../../environments/environment';
import { AddContactOptions } from '../../../helpers/models/addContact.getResponse.model';

const getResponseValues: AddContactOptions = {
  name: '',
  campaign: {
    campaignId: ''
  }, // Taken from URL (Route params)
  email: '',
  dayOfCycle: '0',
  ipAddress: '',
  customFieldValues: []
}

@Component({
  selector: 'app-all-inclusive-corso',
  templateUrl: './all-inclusive-corso.component.html',
  styleUrls: ['./all-inclusive-corso.component.scss']
})
export class AllInclusiveCorsoComponent implements OnInit {

  imageToDisplay: string = '../../../../assets/images/webinar/pari.jpg'
  titleToDisplay: string = 'Indicatore Bande di Migliorino'
  phone: string = ''

  webinarResponse: WebinarResponse
  request: AddContactOptions = getResponseValues

  isEmailValid: boolean = true;
  isPhoneValid: boolean = true;
  isNameValid: boolean = true;
  loading: boolean = false;

  constructor(
    private leadService: LeadsService,
    private renderer2: Renderer2,
    private facebookPixelService: FacebookPixelsService,
    private webinarManager: WebinarManager,
    private router: Router,
    private getResponse: GetResponseService,
    private toastr: ToastrService,
    private seo: SEOService,
    private route: ActivatedRoute
  ) {
    // Facebook & Google Pixels
    this.seo.updateTitle('Webinar Gratuito di Trading Online')
    this.seo.updateOgLocale()
    this.seo.updateOgType('article')
    this.seo.updateOgImage('https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/anteprima-link-webinar.jpg')
    this.seo.updateDescription(`La mia strategia in un Webinar. Partecipa al WEBINAR GRATUITO, ti spiegherò un indicatore formidabile e ti svelerò la mia strategia operativa con cui opero ogni giorno. Prenota il tuo posto Gratis I POSTI SONO LIMITATI, NON PERDERE ALTRO TEMPO`)
    this.route.params.subscribe(params => {
      this.request.campaign.campaignId = params['socialId']
      this.request.dayOfCycle = params['dayOfCycle'] || '0'
    })
  }

  ngOnInit(): void {
    this.getResponse.getClientIp()
      .then((res: any) => {
        if (res) this.request.ipAddress = res.ip
      })
      .catch(() => {
        this.request.ipAddress = '0.0.0.0'
      })
    this.facebookPixelService.injectScrollTracker(this.renderer2)
    this.webinarResponse = this.webinarManager.whichDateNext()
    this.webinarResponse.imageToDisplay != '' ? this.imageToDisplay = this.webinarResponse.imageToDisplay : this.imageToDisplay = this.imageToDisplay
    this.webinarResponse.titleToDisplay != '' ? this.titleToDisplay = this.webinarResponse.titleToDisplay : this.titleToDisplay = this.titleToDisplay
  }

  // GET RESPONSE
  async onSubmit() {
    this.loading = true;
    this.request.customFieldValues.push({
      customFieldId: environment.getResponseCustomFieldId,
      value: [this.phone]
    })
    if (this.request.name != '' &&
      this.request.email != '' &&
      this.phone != '' &&
      this.isNameValid &&
      this.isEmailValid &&
      this.isPhoneValid) {
      await this.getResponse.addContact(this.request)
        .then(async res => {
          this.loading = false
          if (res && res.item1) {
            await this.leadService.createLead({
              leadId: 0,
              fullName: this.request.name,
              email: this.request.email,
              phone: this.phone
            }).then(() => {
              this.toastr.success(`Registrazione avvenuta con successo! Ci vediamo ${this.webinarResponse.nextWebinarDateString} al webinar`, 'Ci vediamo al webinar')
              this.router.navigate(['webinar', 'thankyou', 'all-inclusive-corso'])
            }).catch(async () => {
              await this.router.navigate(['webinar', 'thankyou', 'all-inclusive-corso'])
              //this.toastr.warning("C'è sato un errore imprevisto", 'Attenzione')
            })
          } else {
            //this.toastr.warning('Registrazione non avvenuta, si prega di riprovare o contattare il servizio clienti', 'Registrazione non avvenuta')
            this.toastr.success(`Registrazione avvenuta con successo! Goditi il Webinar`, 'Goditi il webinar')
            await this.router.navigate(['webinar', 'thankyou', 'all-inclusive-corso'])
          }
        })
    } else {
      this.loading = false
      this.resetForm()
      this.toastr.warning('Tutti i campi sono obbligatori', 'Attenzione')
    }
  }

  validateEmail = (email: string) => this.isEmailValid = this.webinarManager.validateEmail(email)

  validatePhone(phone: string) {
    let phoneValidator = this.webinarManager.validatePhone(phone)
    this.isPhoneValid = phoneValidator.isPhoneValid
    this.phone = phoneValidator.correctedPhone
  }

  validateName(name: string) {
    this.request.name = this.webinarManager.convertToTitleCase(name)
    // this.isNameValid = this.webinarManager.validateName(name)
  }

  resetForm() {
    this.request = getResponseValues
    // this.request.name = ''
    // this.request.email = '',
    // this.request.customFieldValues = []
  }
}