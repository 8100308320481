<footer class="text-gray-300 body-font bg-customDark">
  <div class="container px-5 sm:px-0 py-8 mx-auto flex items-center sm:flex-row flex-col">
    <span class="flex m-auto">
      <img class="hidden md:block filter invert object-cover mx-12 object-center mb-2"
        src="../../../assets/images/webinar/logo-rosso-verde.webp" width="150"
        height="29" alt="TTGames Logo">
      <a [routerLink]="['/terms-conditions']" class="text-sm ml-3 text-gray-300 hover:text-redCustom">
        Termini
      </a>
      <a [routerLink]="['/privacy-policy']" class="text-sm ml-3 text-gray-300 hover:text-redCustom">
        Privacy
      </a>
      <a [routerLink]="['/cookie-policy']" class="text-sm ml-3 text-gray-300 hover:text-redCustom">
        Cookie
      </a>
      <a [routerLink]="['/disclaimer']" class="text-sm ml-3 text-gray-300 hover:text-redCustom">
        Disclaimer
      </a>
    </span>
  </div>
</footer>