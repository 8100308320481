<div class="flex flex-col m-auto bg-customDark border border-gray-700 rounded-lg p-5"
     id="signalCard">
  <h1
      class="mx-auto text-white my-2 text-xl underline decoration-redCustom underline-offset-2">
    Ultima Operazione
  </h1>
  <!--FAKE-->
  <div *ngIf="fakeLive !== undefined" class="m-auto my-2">
    <div
      class="flex flex-row mx-auto py-1 px-8 text-sm space-x-1 rounded-full bg-green-500/25 text-green-500">
      <svg class="inline-flex h-5 w-5 animate-pulse" stroke="currentColor"
           stroke-linecap="round"
           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12v.01"></path>
        <path d="M14.828 9.172a4 4 0 0 1 0 5.656"></path>
        <path d="M17.657 6.344a8 8 0 0 1 0 11.314"></path>
        <path d="M9.168 14.828a4 4 0 0 1 0-5.656"></path>
        <path d="M6.337 17.658a8 8 0 0 1 0-11.314"></path>
      </svg>
      <span>Live</span>
    </div>
  </div>
  <!--REAL-->
  <div *ngIf="fakeLive === undefined" class="m-auto my-4">
    <div
         class="bg-green-500/25 text-green-500 flex flex-row mx-auto py-1 px-8 text-sm space-x-1 rounded-full">
        <svg class="inline-flex h-5 w-5 animate-pulse" stroke="currentColor"
             stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 12v.01"></path>
          <path d="M14.828 9.172a4 4 0 0 1 0 5.656"></path>
          <path d="M17.657 6.344a8 8 0 0 1 0 11.314"></path>
          <path d="M9.168 14.828a4 4 0 0 1 0-5.656"></path>
          <path d="M6.337 17.658a8 8 0 0 1 0-11.314"></path>
        </svg>
        <span>Live</span>
    </div>
  </div>
  <div class="flex flex-row mx-auto text-white justify-between space-x-8 text-xs">
    <p>
      <span class="text-xs text-gray-400">Data: </span>
      <span [@fade]="signal">{{signal.sendDate * 1000 | date:'dd/MM/yyyy, HH:mm:ss' }}</span>
    </p>
    <p>
      <span class="text-xs text-gray-400">ID: </span>
      <span [@fade]="signal">{{signal.positionId}}</span>
    </p>
  </div>
  <div class="flex flex-row mx-auto text-white justify-between space-x-8 text-lg my-4">
    <p class="flex flex-col text-center text-center">
      <span [@fade]="signal">{{signal.symbol}}</span>
      <span class="text-center text-xs text-gray-400">Simbolo</span>
    </p>
    <p class="flex flex-col text-center">
      <span [@fade]="signal">{{operation[signal.operation]}}</span>
      <span class="text-center text-xs text-gray-400">Operazione</span>
    </p>
    <p class="flex flex-col text-center">
      <span [@fade]="signal">{{signal.contracts | number : '1.2-2'}}</span>
      <span class="text-center text-xs text-gray-400">Contratti</span>
    </p>
  </div>
  <div class="flex flex-row mx-auto text-white justify-between space-x-8 text-lg">
    <p class="flex flex-col text-center">
      <span [@fade]="signal">{{signal.price | number : '1.2-2'}}</span>
      <span class="text-center text-xs text-gray-400">Quotazione</span>
    </p>
    <div class="flex flex-col text-center">
      <div class="flex flex-row">
        <span [@fade]="signal">
        {{signal.profitAndLoss | currency: 'EUR'}}
      </span>
      </div>
      <span class="text-center text-xs text-gray-400">P/L</span>
    </div>
  </div>
  <div class="flex flex-row mx-auto text-white justify-between space-x-8 text-lg">
    <p class="flex flex-col text-center">
      <span [@fade]="signal">{{signal.stopLoss == '0' ? '-' : signal.stopLoss}}</span>
      <span class="text-center text-xs text-gray-400">Stop Loss</span>
    </p>
    <p class="flex flex-col text-center">
      <span [@fade]="signal">{{signal.takeProfit == '0' ? '-' : signal.takeProfit}}</span>
      <span class="text-center text-xs text-gray-400">Take Profit</span>
    </p>
  </div>
  <div class="flex flex-col mx-auto text-center text-white text-lg mt-2">
      <span [@fade]="signal">
         {{ PL | currency: 'EUR' }}
      </span>
    <span class="text-center text-xs text-gray-400">P/L Cumulativo</span>
  </div>
</div>