import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { SendEmailRequest } from "../../helpers/requests/sendEmail.request";
import { EmailPattern } from "../../helpers/patterns/validators.patterns";
import { EmailService } from "../../helpers/services/email.service";

@Component({
  selector: 'app-form-contatto',
  templateUrl: './form-contatto.component.html',
  styleUrls: ['./form-contatto.component.scss']
})
export class FormContattoComponent {

  emailRequest: SendEmailRequest = {
    fullName: '',
    email: '',
    message: ''
  }

  form: FormGroup
  loading: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private emailService: EmailService) {
    this.form = this.formBuilder.group({
      fullName: new FormControl(this.emailRequest.fullName, [Validators.required]),
      email: new FormControl(this.emailRequest.email, [Validators.required, Validators.pattern(EmailPattern)]),
      message: new FormControl(this.emailRequest.message, [Validators.required])
    })
  }

  get m() {
    return this.form.controls
  }

  async sendMail() {
    if (this.form.valid) {
      this.loading = true
      await this.emailService.sendEmail(this.emailRequest)
        .then(() => {
          this.toastr.success("Messaggio inviato correttamente, attendi una risposta da Top Trading Academy")
        }).catch(() => {
          this.toastr.success("Errore nell'invio del messaggio, riprova o contatta il servizio clienti")
        }).finally(() => {
          this.loading = false;
        })
    } else {
      this.toastr.warning("Compila tutti i campi per inviare il messaggio")
    }
  }

}
