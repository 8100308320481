import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SendEmailRequest} from "../requests/sendEmail.request";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class EmailService {

  constructor(private http: HttpClient) {
  }

  sendEmail(email: SendEmailRequest): Promise<any> {
    return this.http.post(`${environment.apiUrl}/Emails/SendEmailFromCloud`, email)
      .toPromise()
  }
}
