<div class="flex flex-col justify-center text-white min-h-screen w-full h-full bg-customDark bg-cover bg-no-repeat">
    <!-- SEZIONE HERO -->
    <section class="flex pt-12 m-auto w-full xl:w-5/6 4k:w-2/3 bg-cover bg-no-repeat items-center flex-row gap-x-8">
      <!-- TESTO -->
      <div class="flex m-auto flex-col lg:max-w-1/2">
        <div class="flex flex-col">
          <h2 class="flex flex-col md:flex-row px-4  text-sm md:text-xs lg:text-sm text-center md:text-left text-gray-300 uppercase font-bold">
            <span class="text-redCustom">corso gratuito</span>
          </h2>
          <h1 class="px-4 pb-4 text-center md:text-left text-5xl md:text-6xl font-bold capitalize">
            <span>La mia strategia in un corso</span>
          </h1>
        </div>
        <h2 class="py-4 my-2 px-4 mx-auto text-xl max-w-4xl text-left text-white font-semibold ml-8">
          In questo corso gratuito vedrai:
        </h2>
        <ul class="list-disc ml-12 text-gray-300">
          <li>Una strategia <span class="uppercase text-white font-bold">Semplice</span> su <span class="font-bold text-white">cicli e volumi</span></li>
          <li>Come usare <span class="uppercase font-bold text-white">Indicatori Unici</span> di cui abbiamo il <span class="font-bold text-white">Copyright</span></li>
          <li>Un’analisi a <span class="uppercase font-bold text-white">Mercato Aperto</span> in diretta</li>
        </ul>
        <div class="flex flex-col bg-redCustom rounded-lg mx-2 md:mx-8 my-8 px-8 shadow-dark text-black">
          <button [routerLink]="['/webinar', 'all-inclusive-nodate', request.campaign.campaignId, request.dayOfCycle]" fragment='form-iscrizione-webinar' routerLinkActive="router-link-active"
            class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
            <span class="text-black text-xl sm:text-2xl xl:text-3xl font-bold capitalize">Guarda il Corso gratis</span>
          </button>
          <div class="flex flex-col m-auto pb-8 text-center">
            <h3 class="mx-auto text-lg xl:text-xl mt-2 tracking font-semibold">I posti sono limitati, non perdere altro tempo</h3>
          </div>
        </div>
      </div>
      <div class="hidden md:flex relative m-auto flex-col mx-4 bg-cover bg-no-repeat shadow-dark">
        <div class="flex bg-redCustom m-auto rounded-full w-full h-full">
          <picture>
            <source srcset="../../../../assets/images/webinar/simo_webinar.webp" type="image/webp" width="750" height="750" alt="Simone Rubessi, host del Webinar"
              title="Simone Rubessi, trader e host del Webinar">
            <img class="translate-y-8 rounded-full" src="../../../../assets/images/webinar/simo_webinar.png" width="750" height="750" alt="Simone Rubessi, host del Webinar"
              title="Simone Rubessi, trader e host del Webinar">
          </picture>
        </div>
        <h1 class="text-white mx-auto text-3xl px-12 pt-8 font-semibold">Simone Rubessi</h1>
        <p class="mx-auto text-gray-300">Trader</p>
      </div>
    </section>
  
    <!-- SEZIONE IMMAGINI -->
    <section class="flex flex-col mt-12">
      <h1 class="text-4xl md:text-5xl text-center font-bold mx-auto ">Cosa imparerai nel Corso?</h1>
      <div class="flex flex-col mx-auto gap-8 py-12 px-8">
        <div class="flex flex-col md:flex-row-reverse mx-auto  items-center">
          <h2 class="text-2xl px-4 ml-8 text-gray-300 pt-2 pb-4 m-auto -fast md:max-w-md text-center ">
            Imparerai ad individuare i cicli di borsa, la migliore strategia per individuare i massimi ed
            i minimi.
          </h2>
          <div class="flex flex-col">
            <img class="md:max-w-lg" src="../../../../assets/images/webinar/1.jpg" width="750" height="750" alt="indicatore-di-ciclo-tradingonline-analisiciclica"
              title="indicatore-di-ciclo-tradingonline-analisiciclica">
            <p class="text-xs md:text-sm text-gray-300 text-left pb-1">Indicatore di Ciclo</p>
          </div>
        </div>
        <div class="flex flex-col mx-auto">
          <div class="flex flex-col md:flex-row max-w-5xl items-center md:items-end mx-auto slide-in-blurred-right">
            <h2 class="text-2xl text-gray-300 px-4 mr-8 pb-4 m-auto -fast max-w-4xl text-center py-4 ">
              Imparerai ad utilizzare l'indicatore sovrastante che ti permetterà di individuare i punti di
              svolta dei prezzi con una facilità mai vista prima. Esattamente com'è raffigurato
              nell'immagine.
            </h2>
            <div class="flex flex-col">
              <img class="md:max-w-lg" src="{{ imageToDisplay }}" width="750" height="750" alt="indicatore-di-ciclo-tradingonline-analisiciclica"
                title="indicatore-di-ciclo-tradingonline-analisiciclica">
              <p class="text-xs md:text-sm text-gray-300 text-right pb-1">{{ titleToDisplay }}</p>
            </div>
          </div>
        </div>
      </div>
      <h2 class="text-2xl text-ivoryWhite px-4 pb-8 mx-auto -fast max-w-4xl text-center py-4 ">
        Alla fine ti mostrerò la mia strategia in tutte le sue parti, quella che mi permette di vivere
        di trading e che ho costruito dopo anni di studio.
      </h2>
    </section>
    <!-- SEZIONE COUNTDOWN -->
    <section class="flex flex-col bg-redCustom  text-black">
      <button [routerLink]="['/webinar', 'all-inclusive-nodate', request.campaign.campaignId, request.dayOfCycle]" fragment='form-iscrizione-webinar' routerLinkActive="router-link-active"
        class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
        <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">Guarda il Corso gratis</span>
      </button>
      <div class="flex flex-col m-auto pb-8 text-center mt-2">
        <h3 class="mx-auto text-xl mt-2 tracking font-semibold">I posti sono limitati, non perdere altro tempo</h3>
      </div>
    </section>
    <!-- SEZIONE TESTIMONIAL SCRITTI -->
    <section class="text-gray-300 body-font">
      <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-wrap -m-4">
          <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
            <div class="h-full text-center">
              <img alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block" src="https://eu.ui-avatars.com/api/background=D97706&color=000?name=Alessandro+Biagini?"
                alt="test" title="test2" width="50" height="50">
              <p class="leading-relaxed">
                "Non conoscendo niente di finanza fino a qualche tempo fa e cercando un modo per arrotondare il mio stipendio, mi sono imbattuto nel trading. Inizialmente ho avuto scarsissimi risultati,
                sia per il fatto che non ho mai studiato economia e finanza, sia per il fatto che non sono riuscito a trovare formazione valida e che partisse dal mio livello, ovvero da zero. In Top
                Trading Academy ho trovato tutto quello che mi serviva per poter essere profittevole in modo costante seppur con capitali modesti. La formazione si sviluppa partendo da che cos'è il
                trading fino ad arrivare all'analisi ciclica più avanzata, con numerose strategie operative. Davvero un lavoro INCREDIBILE ed ammirevole, vale il prezzo fino all'ultimo centesimo."
              </p>
              <span class="inline-block h-1 w-10 rounded bg-redCustom mt-6 mb-4"></span>
              <h2 class="text-redCustom font-medium title-font tracking-wider text-sm uppercase">Alessandro Biagini</h2>
              <p class="text-ivoryWhite">Cliente All Inclusive</p>
            </div>
          </div>
          <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
            <div class="h-full text-center">
              <img alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block" src="https://eu.ui-avatars.com/api/background=D97706&color=000?name=Max+Fanciulli?"
                alt="test" title="test2" width="50" height="50">
              <p class="leading-relaxed">
                "Ho deciso di acquistare il corso ALL-INCLUSIVE per ampliare le mie conoscenze e per apprendere nuove strategie nonostante viva già di trading. Devo ammettere che questo corso è una vera
                e propria scuola di trading che se avessi avuto quando ho iniziato questa attività avrei risparmiato molti soldi, sia in formazione che in perdite che non avrei subito. Ho tralasciato la
                parte iniziale sull'utilizzo delle piattaforme in quanto mi ritengo già ferrato. Una volta arrivato alla spiegazione degli indicatori li ho trovati veramente innovativi e di facilissima
                interpretazione."
              </p>
              <span class="inline-block h-1 w-10 rounded bg-redCustom mt-6 mb-4"></span>
              <h2 class="text-redCustom font-medium title-font tracking-wider text-sm uppercase">Max Fanciulli</h2>
              <p class="text-ivoryWhite">Cliente All Inclusive</p>
            </div>
          </div>
          <div class="lg:w-1/3 lg:mb-0 p-4">
            <div class="h-full text-center">
              <img alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block" src="https://eu.ui-avatars.com/api/background=D97706&color=000?name=Paolo+Corsi?"
                alt="test" title="test2" width="50" height="50">
              <p class="leading-relaxed">"Grazie Simone... ho finalmente trovato un metodo di trading che mi permette di essere in sintonia con l'andamento dei mercati... devo ancora implementare lo
                studio, ma anche così, quasi
                da principiante dei cicli, i risultati sono davvero ottimi e soprattutto trado con tranquillità. Hasta la Victoria!"</p>
              <span class="inline-block h-1 w-10 rounded bg-redCustom mt-6 mb-4"></span>
              <h2 class="text-redCustom font-medium title-font tracking-wider text-sm uppercase">Paolo Corsi</h2>
              <p class="text-ivoryWhite">Cliente All Inclusive</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- SEZIONE FORM -->
    <section class="flex flex-col bg-customDark text-white">
      <div class="flex flex-col mx-auto py-8">
        <div class="flex flex-col mx-auto py-8" id="form-iscrizione-webinar">
          <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div class="flex flex-col gap-y-4">
              <div class="flex flex-col mx-auto w-80 md:w-96">
                <label for="name" class="label-std-left-validated">Nome</label>
                <input type="text" id="name" name="name" [(ngModel)]="request.name"
                       formControlName="name"
                       class="input-std-dark">
                <label *ngIf="m.name.invalid" class="invalid-label">
                  Attenzione, Nome obbligatorio.
                </label>
              </div>
              <div class="flex flex-col mx-auto w-80 md:w-96">
                <label for="email" class="label-std-left-validated">Email </label>
                <input type="email" id="email" name="email" [(ngModel)]="request.email"
                       formControlName="email"
                       class="input-std-dark">
                <label *ngIf="m.email.invalid" class="invalid-label">
                  Attenzione, Email non valida.
                </label>
              </div>
              <div class="flex flex-col mx-auto w-80 md:w-96">
                <label for="phone" class="label-std-left-validated">Cellulare</label>
                <input type="text" id="phone" name="phone" [(ngModel)]="lead.phone"
                       formControlName="phone"
                       class="input-std-dark">
                <label *ngIf="m.phone.invalid" class="invalid-label">
                  Attenzione, telefono non valido.
                </label>
              </div>
              <!--PRIVACY-->
              <div class="col-span-6 sm:col-span-6 text-gray-300 m-auto text-xs text-left mt-2 space-y-4">
                <!--privacyTrattamento-->
                <div class="flex flex-row space-x-4">
                  <div class="flex flex-col">
                    <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                      Acconsento al trattamento dei dati secondo quanto definito dalla vostra
                      Informativa sulla Privacy esposta in questo sito.
                    </p>
                    <p *ngIf="m.privacyTrattamento.invalid" [@fade]="m.privacyTrattamento.invalid"
                       class="invalid-label">
                      Consenso obbligatorio
                    </p>
                  </div>
                  <div class="flex flex-col justify-center">
                    <p>Si</p>
                    <label for="privacyTrattamento-true"></label>
                    <input id="privacyTrattamento-true" [value]="true"
                           class="radio-redCustom"
                           formControlName="privacyTrattamento"
                           type="radio">
                  </div>
                  <div class="flex flex-col justify-center">
                    <p>No</p>
                    <label for="privacyTrattamento-false"></label>
                    <input id="privacyTrattamento-false" [value]="false"
                           class="radio-redCustom"
                           formControlName="privacyTrattamento"
                           type="radio">
                  </div>
                </div>
                <!--privacyContatto-->
                <div class="flex flex-row space-x-4">
                  <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                    Acconsento ad essere contattato da un vostro consulente e ricevere
                    tramite sms, e-mail o notifiche Push vostre informazioni
                    Pubblicitarie.
                  </p>
                  <div class="flex flex-col justify-center">
                    <p>Si</p>
                    <label for="privacyContatto-true"></label>
                    <input id="privacyContatto-true" [value]="true" class="radio-redCustom"
                           formControlName="privacyContatto"
                           type="radio">
                  </div>
                  <div class="flex flex-col justify-center">
                    <p>No</p>
                    <label for="privacyContatto-false"></label>
                    <input id="privacyContatto-false" [value]="false" class="radio-redCustom"
                           formControlName="privacyContatto"
                           type="radio">
                  </div>
                </div>
                <!--privacyThirdParty-->
                <div class="flex flex-row space-x-4">
                  <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                    Acconsento alla comunicazione e/o cessione di dati personali a società
                    terze per le loro attività di marketing, quali l’invio di materiale pubblicitario,
                    la vendita diretta, il compimento di ricerche di mercato e di comunicazione commerciale.
                  </p>
                  <div class="flex flex-col justify-center">
                    <p>Si</p>
                    <label for="privacyThirdParty-true"></label>
                    <input id="privacyThirdParty-true" [value]="true" class="radio-redCustom"
                           formControlName="privacyThirdParty"
                           type="radio">
                  </div>
                  <div class="flex flex-col justify-center">
                    <p>No</p>
                    <label for="privacyThirdParty-false"></label>
                    <input id="privacyThirdParty-false" [value]="false" class="radio-redCustom"
                           formControlName="privacyThirdParty"
                           type="radio">
                  </div>
                </div>
              </div>
              <label class="my-2 m-auto font-bold">
                Attenzione: tutti i campi sono obbligatori.
              </label>
            </div>
            <div class="flex m-auto justify-center">
              <button type="submit" class="button-std justify-center">
                <span *ngIf="!loading" [ngClass]="{'opacity-50': !form.valid}"
                  class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">
                  Prenota il tuo posto gratis
                </span>
                <span *ngIf="loading" class="text-black text-xl sm:text-2xl lg:text-xl font-bold">Carico</span>
                <app-loading-animation-small *ngIf="loading" class="ml-2"></app-loading-animation-small>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="flex flex-col m-auto py-8 text-center">
        <h3 class="mx-auto text-xl mt-2 tracking font-semibold">I posti sono limitati, non perdere altro tempo</h3>
      </div>
    </section>
    <p class="text-gray-400 text-xs mx-auto py-12 text-center mt-96">
      DISCLAIMER: Questo sito non è parte di Facebook o Facebook Inc. Inoltre questo sito non è autorizzato da Facebook in alcun modo. Facebook è un marchio di Facebook Inc.
    </p>
  </div>