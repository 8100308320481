import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebinarResponse } from 'src/app/helpers/models/webinarResponse.model';
import { GetResponseService } from 'src/app/helpers/services/getResponse.service';
import { SEOService } from 'src/app/helpers/services/seo.service';
import { WebinarManager } from 'src/app/helpers/services/webinarManager.service';
import { environment } from 'src/environments/environment';
import { AddContactOptions } from '../../../helpers/models/addContact.getResponse.model';

const getResponseValues: AddContactOptions = {
  name: '',
  campaign: {
    campaignId: ''
  }, // Taken from URL (Route params)
  email: '',
  dayOfCycle: '0',
  ipAddress: '',
  customFieldValues: []
}
@Component({
  selector: 'app-all-inclusive-es',
  templateUrl: './all-inclusive-es.component.html',
  styleUrls: ['./all-inclusive-es.component.scss']
})
export class AllInclusiveEsComponent implements OnInit {

  imageToDisplay: string = '../../../../assets/images/webinar/pari.jpg'
  titleToDisplay: string = 'El indicador Bandas de Migliorino'
  phone: string = ''

  webinarResponse: WebinarResponse
  request: AddContactOptions = getResponseValues

  isEmailValid: boolean = true;
  isPhoneValid: boolean = true;
  isNameValid: boolean = true;
  loading: boolean = false;

  constructor(
    private webinarManager: WebinarManager,
    private router: Router,
    private getResponse: GetResponseService,
    private toastr: ToastrService,
    private seo: SEOService,
    private route: ActivatedRoute
  ) {
    // Facebook & Google Pixels
    this.seo.updateTitle('Webinar Gratuito di Trading Online')
    this.seo.updateOgLocale()
    this.seo.updateOgType('article')
    this.seo.updateOgImage('https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/anteprima-link-webinar.jpg')
    this.seo.updateDescription(`¿Cansado de perder dinero? Apúntate al webinar gratuito, te explicaré un formidable indicador y te desvelaré mi estrategia operativa con la que trabajo cada día.`)
    this.route.params.subscribe(params => {
      this.request.campaign.campaignId = params['socialId']
      this.request.dayOfCycle = params['dayOfCycle'] || '0'
    })
  }

  ngOnInit(): void {
    this.getResponse.getClientIp()
      .then((res: any) => {
        if (res) this.request.ipAddress = res.ip
      })
    this.webinarResponse = this.webinarManager.whichDateNext(true, true)
    this.webinarResponse.imageToDisplay != '' ? this.imageToDisplay = this.webinarResponse.imageToDisplay : this.imageToDisplay = this.imageToDisplay
    this.webinarResponse.titleToDisplay != '' ? this.titleToDisplay = this.webinarResponse.titleToDisplay : this.titleToDisplay = this.titleToDisplay
  }

  // GET RESPONSE
  onSubmit() {
    this.loading = true;
    this.request.customFieldValues.push({
      customFieldId: environment.getResponseCustomFieldId,
      value: [this.phone]
    })
    if (this.request.name != '' &&
      this.request.email != '' &&
      this.phone != '' &&
      this.isNameValid &&
      this.isEmailValid &&
      this.isPhoneValid) {
      this.getResponse.addContact(this.request)
        .then(res => {
          this.loading = false
          if (res && res.item1) {
            this.router.navigate(['webinar', 'es', 'thankyou', 'all-inclusive'])
            this.toastr.success(`¡El registro fue exitoso!  Nos vemos ${this.webinarResponse.nextWebinarDateString} en el webinar`, 'Nos vemos en el webinar')
          } else {
            this.toastr.warning('Registrazione non avvenuta, si prega di riprovare o contattare il servizio clienti', 'Registrazione non avvenuta')
            window.location.reload()
          }
        })
    } else {
      this.loading = false
      this.resetForm()
      this.toastr.warning('Todos los campos son obligatorios', 'Atención')
    }
  }

  validateEmail = (email: string) => this.isEmailValid = this.webinarManager.validateEmail(email)

  validatePhone(phone: string) {
    let phoneValidator = this.webinarManager.validatePhone(phone)
    this.isPhoneValid = phoneValidator.isPhoneValid
    this.phone = phoneValidator.correctedPhone
  }

  validateName (name: string) {
    this.request.name = this.webinarManager.convertToTitleCase(name)
    // this.isNameValid = this.webinarManager.validateName(name)
  }

  resetForm() {
    this.request = getResponseValues
    // this.request.name = ''
    // this.request.email = '',
    // this.request.customFieldValues = []
  }
}
