<div class="flex flex-col justify-center text-white min-h-screen w-full h-full bg-customDark">
  <!-- SEZIONE HERO -->
  <section class="flex flex-col lg:flex-row pt-12 m-auto w-full xl:w-5/6 lg:space-x-8 items-center flex-row xl:gap-x-8">
    <!-- TESTO -->
    <div class="flex m-auto flex-col items-center lg:items-start lg:max-w-2/3">
      <div class="flex flex-col">
        <div class="flex justify-center lg:justify-start">
          <h2 class="flex flex-col md:flex-row px-4 pb-2 text-sm md:text-xs lg:text-sm text-center lg:text-left text-gray-300 uppercase font-bold">
            <span class="text-redCustom">Servizio</span>
            <span class="hidden md:block px-2">&#183;</span>
            <span>Attiva Subito</span>
          </h2>
        </div>
        <h1 class="flex flex-col px-4 pb-4 md:pb-8 text-center lg:text-left text-5xl md:text-6xl font-bold capitalize">
          <span>Servizio</span>
          <span class="underline decoration-redCustom underline-offset-2">Previsionali</span>
        </h1>
      </div>
      <h2 class="py-4 my-2 text-center md:text-left px-4 mx-auto text-xl max-w-4xl text-left text-white font-semibold ml-8">
        Soli 50 centesimi al mese per restare aggiornati sull’andamento futuro dei future Dax e S&P50.
      </h2>
      <!--<div class=" border-gray-300 border-0 border-l-2 ml-8">
        <p class="py-4 my-2 px-4 mx-auto max-w-4xl text-left text-gray-300 ml-2">
          Acquistando Il servizio Previsionali potrai usufruire del
          <span class="font-bold text-white">coupon PREVISIONALISPECIAL</span> per ottenere
          uno <span class="font-bold text-white">sconto di €30</span>
          per ogni ulteriore acquisto presso la nostra accademia.
        </p>
        <p class="my-2 px-4 mx-auto max-w-4xl text-xs text-left text-gray-300 ml-2">
          Nota: il coupon è cumulabile con ogni altra promozione, ma non può essere utilizzato
          per rinnovare il servizio previsionali. Il coupon ha una durata di 3 mesi.
        </p>
      </div>-->
     <!-- <p class="py-4 my-2 px-4 mx-auto max-w-4xl text-left text-gray-300 ml-8 border-gray-3400 border-0 border-l-2">
        Acquistando il servizio Previsionali riceverai in <span class="font-bold text-white">omaggio per 10 giorni</span>
        la visione del <span class="font-bold text-white">Servizio Ammiratore</span>, un servizio che
        <span class="font-bold text-white">invia notifiche in tempo reale</span> delle operazioni effettuate
        da un nostro trader professionista per poter vedere come opera ogni giorno con successo sui mercati.
      </p>-->
      <!-- TIMER -->
      <div class="flex flex-col bg-redCustom rounded-lg mx-2 md:mx-5 px-8 my-8 shadow-dark text-black w-full px-2">
        <div class="flex flex-col m-auto pb-8 text-center">
          <button (click)="redirectToShop()"
            class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
            <span class="text-black text-xl lg:text-2xl xl:text-3xl font-bold capitalize">Acquista Ora</span>
          </button>
          <h3 class="mx-auto flex flex-col mt-2 tracking">
            <span class="text-xl font-bold">
              {{ timerString | countdown: false: true | async }}
            </span>
            <span class="font-semibold">Per effettuare l'acquisto</span>
          </h3>
        </div>
      </div>
      <!-- END TIMER -->
    </div>
    <!-- VIDEO -->
    <div class="flex w-full h-full flex-col relative m-auto mx-2">
      <div class="flex w-full h-full mx-auto overflow-hidden px-4 md:px-0">
        <app-video-player
          class="w-full h-full"
          [facadeId]="'previsionali-preview'"
          [vimeoLink]="'https://vimeo.com/742666495'">
        </app-video-player>
      </div>
    </div>
  </section>

  <!-- SEZIONE IMMAGINI -->
  <section class="flex flex-col w-full h-full mt-12">
    <h1 class="text-3xl md:text-4xl text-center font-bold mx-auto underline decoration-redCustom underline-offset-2">
      Cosa comprende il servizio Previsionali?
    </h1>
    <div class="flex flex-row text-gray-300 m-auto md:max-w-3/4">
      <p class="text-center mx-auto px-2 sm:px-0 w-full md:w-2/3 mt-8">
        Il servizio comprende
        <span class="font-bold text-white">previsioni professionali e sempre aggiornate</span>  effettuate dal nostro
        <span class="font-bold text-white">Analista Marco Magnani</span> sui mercati Dax e S&P500.
      </p>
      <!--<p class="text-right mx-auto px-2 sm:px-0 w-1/2 mt-8">
        Le previsioni tendono ad identificare il <span class="font-bold text-white">quadro ciclico dei mercati</span>
        analizzati per poterne comprendere l'andamento futuro, sviluppando previsioni di lungo, medio e breve periodo.
      </p>-->
    </div>
    <div class="flex flex-col m-auto w-full h-full gap-8 py-12 px-8 text-md lg:text-lg">
      <!--PRIMA SEZIONE-->
      <div class="flex flex-col md:flex-row mx-auto items-center space-y-4">
        <div class="flex flex-col w-full h-full">
          <picture>
            <source srcset="../../../../assets/images/webinar/previsionali/previsionali_immagine_1.png" type="image/png">
            <img (click)="open(0, 'Medie Mobili Cicliche e Target')" class="md:max-w-lg cursor-pointer"
                  src="../../../../assets/images/webinar/previsionali/previsionali_immagine_1.png"
                 alt="Medie Mobili Cicliche e Target"
                 title="Medie Mobili Cicliche e Target">
          </picture>
          <p class="text-xs md:text-sm text-gray-300 text-left pb-1">Medie Mobili Cicliche e Target</p>
        </div>
        <div class="m-auto">
          <ul class="list-disc md:translate-x-4">
            <li>Rappresentazione MM cicliche</li>
            <li>Rilievo target di prezzo con MM cicliche</li>
            <li>Monitoraggio rispetto andamenti ciclici</li>
            <li>Rilievo supporti e resistenze ciclici</li>
          </ul>
        </div>
      </div>
      <!--SECONDA SEZIONE-->
      <div class="flex flex-col mx-auto">
        <div
          class="flex flex-col md:flex-row-reverse max-w-5xl md:space-x-8 items-center md:items-end mx-auto">
          <div class="flex flex-col">
            <picture>
              <source srcset="../../../../assets/images/webinar/previsionali/previsionali_immagine_2.png" type="image/png">
              <img (click)="open(1, 'Battleplan e target temporali lungo periodo')" class="md:max-w-lg cursor-pointer"
                   src="../../../../assets/images/webinar/previsionali/previsionali_immagine_2.png"
                   alt="Battleplan e target temporali lungo periodo"
                   title="Battleplan e target temporali lungo periodo">
            </picture>
            <p class="text-xs md:text-sm text-gray-300 text-right pb-1">Battleplan e target temporali lungo periodo</p>
          </div>
          <div class="m-auto">
            <ul class="list-disc">
              <li>Identificazione cicli di lungo periodo</li>
              <li>Nell’esempio di un ciclo economico</li>
              <li>Identificazione target di prezzo con raffronti MM e Fasatura ciclica</li>
              <li>Rilievo ed identificazione trend futuro</li>
            </ul>
          </div>
        </div>
      </div>
      <!--TERZA SEZIONE-->
      <div class="flex flex-col md:flex-row mx-auto items-center text-md lg:text-lg">
        <div class="flex flex-col">
          <picture>
            <source srcset="../../../../assets/images/webinar/previsionali/previsionali_immagine_3.png" type="image/png">
            <img (click)="open(2, 'Indicatori di Svolta proprietari')" class="md:max-w-lg cursor-pointer"
                 src="../../../../assets/images/webinar/previsionali/previsionali_immagine_3.png"
                 alt="Indicatori di Svolta proprietari"
                 title="Indicatori di Svolta proprietari">
          </picture>
          <p class="text-xs md:text-sm text-gray-300 text-left pb-1">Indicatori di Svolta proprietari</p>
        </div>
        <div class="m-auto">
          <ul class="list-disc md:translate-x-8 max-w-xl">
            <li>Monitoraggio Indicatori proprietari che evidenziano inversioni</li>
            <li>Raffronto degli stessi con quadri volumetrici </li>
            <li>Identificazione punti di possibile svolta nel medio periodo</li>
            <li>Sviluppo possibili ipotesi di andamento</li>
          </ul>
        </div>
      </div>
      <!--QUARTA SEZIONE-->
      <div class="flex flex-col mx-auto">
        <div
          class="flex flex-col md:flex-row-reverse max-w-5xl space-x-8 items-center md:items-end mx-auto">
          <div class="flex flex-col">
            <picture>
              <source srcset="../../../../assets/images/webinar/previsionali/previsionali_immagine_4.png" type="image/png">
              <img (click)="open(3, 'Previsioni di lungo termine')" class="md:max-w-lg cursor-pointer"
                   src="../../../../assets/images/webinar/previsionali/previsionali_immagine_4.png"
                   alt="Previsioni di lungo termine"
                   title="Previsioni di lungo termine">
            </picture>
            <p class="text-xs md:text-sm text-gray-300 text-right pb-1">Previsioni di lungo termine</p>
          </div>
          <div class="m-auto pb-8 lg:pb-2">
            <ul class="list-disc">
              <li>Previsioni di lungo periodo </li>
              <li>Target di prezzo raggiungibili</li>
              <li>Archi temporali necessari per il raggiungimento dei target</li>
              <li>Identificazione delle variabili previsionali</li>
            </ul>
          </div>
        </div>
      </div>
      <!--QUINTA SEZIONE-->
      <div class="flex flex-col md:flex-row mx-auto items-center text-md lg:text-lg">
        <div class="flex flex-col">
          <picture>
            <source srcset="../../../../assets/images/webinar/previsionali/previsionali_immagine_5.png" type="image/png">
            <img (click)="open(4, 'Battleplan e target temporali di medio periodo')" class="md:max-w-lg cursor-pointer"
                 src="../../../../assets/images/webinar/previsionali/previsionali_immagine_5.png"
                 alt="Battleplan e target temporali di medio periodo"
                 title="Battleplan e target temporali di medio periodo">
          </picture>
          <p class="text-xs md:text-sm text-gray-300 text-left pb-1">Battleplan e target temporali di medio periodo</p>
        </div>
        <div class="m-auto pb-8 lg:pb-2">
          <ul class="list-disc md:translate-x-8">
            <li>Quadrature volumetriche di medio periodo</li>
            <li>Quadrature Cicliche di medio periodo</li>
            <li>Livelli professionali da recuperare</li>
            <li>Monitoraggio del regolare andamento della tendenza</li>
            <li>Monitoraggio del regolare andamento della tendenza</li>
          </ul>
        </div>
      </div>
      <!--SESTA SEZIONE-->
      <div class="flex flex-col mx-auto">
        <div
          class="flex flex-col md:flex-row-reverse max-w-5xl space-x-4 items-center md:items-end mx-auto">
          <div class="flex flex-col">
            <picture>
              <source srcset="../../../../assets/images/webinar/previsionali/previsionali_immagine_6.png" type="image/png">
              <img (click)="open(5, 'Previsioni di Breve e Medio Periodo')" class="md:max-w-lg cursor-pointer"
                   src="../../../../assets/images/webinar/previsionali/previsionali_immagine_6.png"
                   alt="Previsioni di Breve e Medio Periodo"
                   title="Previsioni di Breve e Medio Periodo">
            </picture>
            <p class="text-xs md:text-sm text-gray-300 text-right pb-1">Previsioni di Breve e Medio Periodo</p>
          </div>
          <div class="m-auto pb-8 lg:pb-2">
            <ul class="list-disc">
              <li>La convergenza di tutti i nostri strumenti di analisi</li>
              <li>Permette di realizzare previsioni di andamento del mercato analizzato</li>
              <li>Arrivando anche a prevedere l’andamento più probabile</li>
              <li>Nel il medio periodo.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <h1 class="text-3xl md:text-4xl text-center font-bold mx-auto underline decoration-redCustom underline-offset-2">
      2 Mercati Analizzati
    </h1>
    <p class="text-gray-300 px-4 pb-8 mx-auto max-w-4xl text-center py-4">
      I criteri illustrati sono gli stessi utilizzati per effettuare le previsioni dei 2 mercati analizzati.
      Soli <span class="font-bold text-white">50 centesimi al mese</span> per trarre un grande vantaggio nel
      <span class="font-bold text-white">sapere cosa potrebbe fare il mercato</span> nel
      medio periodo, da nessuna altra parte potrai ottenere tanto ad un prezzo così basso.<br>
      <span class="font-bold text-white">Acquista subito il servizio e fatti un grade regalo!</span>
    </p>
    <p class="text-gray-300 px-4 pb-8 mx-auto max-w-4xl text-center py-4">
      Iscrivendoti a questo servizio riceverai inoltre un invito gratuito al webinar di presentazione della nostra
       strategia <span class="font-bold text-white">“trading ciclico”</span>, potrai partecipare come invitato VIP gratuitamente.
    </p>
  </section>
  <section>
    <!-- TIMER -->
    <div class="flex flex-col bg-redCustom rounded-lg mx-2 md:mx-8 my-8 px-8 text-black">
      <div class="flex flex-col m-auto pb-8 text-center">
        <button (click)="redirectToShop()"
          class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
          <span class="text-black text-xl lg:text-2xl xl:text-3xl font-bold capitalize">Acquista Ora</span>
        </button>
        <h3 class="mx-auto flex flex-col mt-2 tracking">
            <span class="text-xl font-bold">
              {{ timerString | countdown: false: true | async }}
            </span>
          <span class="font-semibold">Per effettuare l'acquisto</span>
        </h3>
      </div>
    </div>
    <!-- END TIMER -->
  </section>
  <p class="text-gray-400 text-xs mx-auto py-12 text-center mt-96">
    DISCLAIMER: Questo sito non è parte di Facebook o Facebook Inc. Inoltre questo sito non è autorizzato da Facebook in alcun modo. Facebook è un marchio di Facebook Inc.
  </p>
</div>