import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss']
})
export class WhatsappButtonComponent implements OnInit {

  phone: string;
  constructor() { }

  ngOnInit(): void {
    window.location.href.indexOf('es') > -1 ? this.phone = '+393314323104' : this.phone = '+393473531929'
  }
}
