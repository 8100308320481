import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddContactOptions } from '../../../helpers/models/addContact.getResponse.model';
import { FacebookPixelsService } from '../../../helpers/services/facebookPixels.services';
import { GetResponseService } from '../../../helpers/services/getResponse.service';
import { SEOService } from '../../../helpers/services/seo.service';
import { WebinarManager } from '../../../helpers/services/webinarManager.service';
import { environment } from '../../../../environments/environment';
import { LeadsService } from 'src/app/helpers/services/leads.service';
import { Detail } from "../../../helpers/models/details.model";

const getResponseValues: AddContactOptions = {
  name: '',
  campaign: {
    campaignId: ''
  }, // Taken from URL (Route params)
  email: '',
  dayOfCycle: '0',
  customFieldValues: []
}

@Component({
  selector: 'app-smart-volume-nodate',
  templateUrl: './smart-volume-nodate.component.html',
  styleUrls: ['./smart-volume-nodate.component.scss']
})
export class SmartVolumeNodateComponent implements OnInit {
  phone: string = ''

  request: AddContactOptions = getResponseValues

  isEmailValid: boolean = true;
  isPhoneValid: boolean = true;
  isNameValid: boolean = true;
  loading: boolean = false;

  constructor(
    private leadService: LeadsService,
    private renderer2: Renderer2,
    private facebookPixelService: FacebookPixelsService,
    private seo: SEOService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private getResponse: GetResponseService,
    private webinarManager: WebinarManager) {
    // Facebook & Google Pixels
    this.seo.updateTitle('Webinar Gratuito di Trading Online')
    this.seo.updateOgLocale()
    this.seo.updateOgType('article')
    this.seo.updateOgImage('https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/anteprima-link-webinar.jpg')
    this.seo.updateDescription(`I volumi nel trading spiegati in modo semplice. Applicabili fin da subito con una strategia non discrezionale anche per chi non ha esperienza.`)
    this.route.params.subscribe(params => {
      this.request.campaign.campaignId = params['socialId']
      this.request.dayOfCycle = params['dayOfCycle'] || '0'
    })
  }

  async ngOnInit(){
    this.getResponse.getClientIp()
      .then((res: any) => {
        if (res) this.request.ipAddress = res.ip
      })
      .catch(() => {
        this.request.ipAddress = '0.0.0.0'
      })
    await this.facebookPixelService.injectScrollTracker(this.renderer2)
  }

  // jIbor id lista smart-volume
  // GET RESPONSE
  async onSubmit() {
    this.loading = true;
    this.request.customFieldValues.push({
      customFieldId: environment.getResponseCustomFieldId,
      value: [this.phone]
    })
    if (this.request.name != '' &&
      this.request.email != '' &&
      this.phone != '' &&
      this.isNameValid &&
      this.isEmailValid &&
      this.isPhoneValid) {
      await this.getResponse.addContact(this.request)
        .then(async res => {
          this.loading = false
          if (res && res.item1) {
            await this.leadService.createLead({
              leadId: 0,
              fullName: this.request.name,
              email: this.request.email,
              phone: this.phone
            }).then(async () => {
              this.toastr.success(`Registrazione avvenuta con successo! Goditi il Webinar`, 'Goditi il webinar')
              await this.navigateToThankYou()
            }).catch(async () => {
              await this.navigateToThankYou()
              //this.toastr.warning("C'è stato un errore imprevisto", 'Attenzione')
            })
          } else {
            //this.toastr.warning('Registrazione non avvenuta, si prega di riprovare o contattare il servizio clienti', 'Registrazione non avvenuta')
            this.toastr.success(`Registrazione avvenuta con successo! Goditi il Webinar`, 'Goditi il webinar')
            await this.navigateToThankYou()
          }
        })
    } else {
      this.loading = false
      this.resetForm()
      this.toastr.warning('Tutti i campi sono obbligatori', 'Attenzione')
    }
  }

  async navigateToThankYou() {
    const params = {
      name: this.request.name,
      email: this.request.email,
      phone: this.phone
    }
    await this.router.navigate(['/webinar/thankyou/smart-volume-nodate'], { queryParams: params })
  }

  validateEmail = (email: string) => this.isEmailValid = this.webinarManager.validateEmail(email)

  validatePhone(phone: string) {
    let phoneValidator = this.webinarManager.validatePhone(phone)
    this.isPhoneValid = phoneValidator.isPhoneValid
    this.phone = phoneValidator.correctedPhone
  }

  validateName(name: string) {
    this.request.name = this.webinarManager.convertToTitleCase(name)
  }

  resetForm() {
    this.request = getResponseValues
  }
}