<div class="flex flex-col justify-center text-white min-h-screen w-full h-full bg-customDark bg-cover bg-no-repeat">
    <!-- SEZIONE HERO -->
    <section class="flex pt-12 m-auto w-full xl:w-5/6 4k:w-2/3 bg-cover bg-no-repeat items-center flex-row gap-x-8">
      <!-- TESTO -->
      <div class="flex m-auto flex-col lg:max-w-1/2">
        <div class="flex flex-col">
          <h2 class="flex flex-col md:flex-row px-4 pb-2 text-sm md:text-xs lg:text-sm text-center md:text-left text-gray-300 uppercase font-bold">
            <span class="text-redCustom">webinar gratuito</span>
          </h2>
          <h1 class="pt-4 md:pt-0 px-4 pb-4 md:pb-8 text-center md:text-left text-5xl md:text-6xl font-bold capitalize">
            <span>I volumi nel trading mai così semplici</span>
          </h1>
        </div>
        <div class="flex flex-col max-w-4xl text-left">
          <h2 class="text-center md:text-left pb-4 my-2 px-4 text-2xl text-ivoryWhite font-semibold">
            In questo webinar gratuito imparerai:
          </h2>
          <ul class="ml-8 md:ml-16 list-disc space-y-2">
            <li>Come utilizzare i volumi in una <span class="font-bold tracking-wide capitalize">strategia Intraday Estremamente Semplice</span>.</li>
            <li>Una strategia da <span class="font-bold tracking-wide">100€ al giorno</span> con un capitale da poco più di mille euro. </li>
            <li>Ad utilizzare <span class="font-bold tracking-wide capitalize">indicatori unici</span>.</li>
            <li>Concetti immediatamente applicabili.</li>
          </ul>
        </div>
        <div class="flex flex-col bg-redCustom rounded-lg mx-2 md:mx-8 my-8 px-8 shadow-dark text-black">
          <button [routerLink]="['/webinar', 'smart-volume-nodate', request.campaign.campaignId, request.dayOfCycle]" fragment='form-iscrizione-webinar' routerLinkActive="router-link-active"
            class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
            <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">Guarda il webinar gratis</span>
          </button>
          <div class="flex flex-col m-auto pb-8 text-center">
            <h3 class="mx-auto text-xl mt-2 tracking font-semibold">I posti sono limitati, non perdere altro tempo</h3>
          </div>
        </div>
      </div>
      <div class="hidden md:flex relative m-auto flex-col mx-4 bg-cover bg-no-repeat shadow-dark">
        <div class="flex bg-redCustom m-auto rounded-full w-full h-full">
          <picture>
            <source srcset="../../../../assets/images/webinar/simo_webinar.webp" type="image/webp" width="750" height="750" alt="Simone Rubessi, host del Webinar"
              title="Simone Rubessi, trader e host del Webinar">
            <img class="translate-y-8 rounded-full" src="../../../../assets/images/webinar/simo_webinar.png" width="750" height="750" alt="Simone Rubessi, host del Webinar"
              title="Simone Rubessi, trader e host del Webinar">
          </picture>
        </div>
        <h1 class="text-white mx-auto text-3xl px-12 pt-8 font-semibold">Simone Rubessi</h1>
        <p class="mx-auto text-gray-300">Trader</p>
      </div>
    </section>
    <!-- SEZIONE IMMAGINI -->
    <section class="flex flex-col mt-12">
      <h1 class="text-4xl md:text-5xl text-center font-bold mx-auto ">Cosa imparerai nel Webinar?</h1>
      <div class="flex flex-col mx-auto gap-8 py-12 px-8">
        <div class="flex flex-col md:flex-row-reverse mx-auto  items-center">
          <h2 class="text-2xl px-4 ml-8 text-gray-300 pt-2 pb-4 m-auto -fast md:max-w-md text-center ">
            L'importanza dei volumi negli ingressi, in quanto sono l'unica cosa non manipolabile.
          </h2>
          <div class="flex flex-col">
            <img class="md:max-w-lg" src="../../../../assets/images/webinar/smart-volume/volume-profile.jpg" width="750" height="750" alt="Indicatore Volume Profile"
              title="indicatore-di-ciclo-tradingonline-analisiciclica">
            <p class="text-xs md:text-sm text-gray-300 text-left pb-1">Indicatore Volume Profile</p>
          </div>
        </div>
        <div class="flex flex-col mx-auto">
          <div class="flex flex-col md:flex-row max-w-5xl items-center md:items-end mx-auto slide-in-blurred-right">
            <h2 class="text-2xl text-gray-300 px-4 mr-8 pb-4 m-auto -fast max-w-4xl text-center py-4 ">
              A fare un operazione al giorno da 20/30 punti sul DAX semplice e <span class="font-bold uppercase">non discrezionale</span>.
            </h2>
            <div class="flex flex-col">
              <img class="md:max-w-lg" src="../../../../assets/images/webinar/smart-volume/order-book.jpg" width="750" height="750" alt="indicatore-di-ciclo-tradingonline-analisiciclica"
                title="indicatore-di-ciclo-tradingonline-analisiciclica">
              <p class="text-xs md:text-sm text-gray-300 text-right pb-1">Book Operativo</p>
            </div>
          </div>
        </div>
      </div>
      <h2 class="text-3xl text-ivoryWhite px-4 pb-8 mx-auto -fast max-w-4xl text-center py-4 ">
        Potresti non avere un'altra occasione.
      </h2>
    </section>
  
    <!-- SEZIONE FORM -->
    <section class="flex flex-col bg-redCustom  text-black">
      <div class="flex flex-col mx-auto py-8">
        <form (ngSubmit)="onSubmit()" id="form-iscrizione-webinar">
          <div class="flex flex-col gap-y-4">
            <div class="flex flex-col mx-auto w-80 md:w-96">
              <label for="name" class="block text-sm font-bold">Nome <span *ngIf="!isNameValid">*</span></label>
              <input autocomplete="off" type="text" id="name" name="name" [(ngModel)]="request.name" (change)="validateName(request.name)"
                class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
              <label *ngIf="!isNameValid" class="text-black font-bold pt-2" for="phoneError">Attenzione, Nome obbligatorio.</label>
            </div>
            <div class="flex flex-col mx-auto w-80 md:w-96">
              <label for="email" class="block text-sm font-bold">Email <span *ngIf="!isEmailValid">*</span></label>
              <input autocomplete="off" type="email" id="email" name="email" [(ngModel)]="request.email" (change)="validateEmail(request.email)"
                class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
              <label *ngIf="!isEmailValid" class="text-black font-bold pt-2" for="phoneError">Attenzione, Email non valida.</label>
            </div>
            <div class="flex flex-col mx-auto w-80 md:w-96">
              <label for="phone" class="block text-sm font-bold">Cellulare <span *ngIf="!isPhoneValid">*</span></label>
              <input autocomplete="off" type="text" id="phone" name="phone" [(ngModel)]="phone"
                class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
              <label *ngIf="!isPhoneValid" class="text-black font-bold pt-2" for="phoneError">Attenzione, telefono non valido.</label>
            </div>
            <label class="text-black m-auto font-bold" for="Information">Attenzione: tutti i campi sono obbligatori.</label>
          </div>
          <button type="submit"
            class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
            <span *ngIf="!loading" class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">Guarda il webinar gratis</span>
            <span *ngIf="loading" class="text-black text-xl sm:text-2xl lg:text-xl font-bold">Carico...</span>
            <div *ngIf="loading" class="ml-4 mt-1 animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-black">
            </div>
          </button>
        </form>
      </div>
      <div class="flex flex-col m-auto py-8 text-center">
        <h3 class="mx-auto text-xl mt-2 tracking">I posti sono limitati, non perdere altro tempo</h3>
      </div>
    </section>
    <p class="text-gray-400 text-xs mx-auto py-12 text-center mt-96">
      DISCLAIMER: Questo sito non è parte di Facebook o Facebook Inc. Inoltre questo sito non è autorizzato da Facebook in alcun modo. Facebook è un marchio di Facebook Inc.
    </p>
  </div>