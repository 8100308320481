<div class="relative font-urbanist flex h-screen">
  <div class="flex w-full bg-orange-blob-dark bg-cover bg-no-repeat">
    <div class="px-12 container mx-auto flex md:flex-row flex-col items-center" style="margin-top: 120px;">
      <div class="container mx-auto flex sm:px-10 md:flex-row flex-col items-center">
        <div class="transform translate-y-60 sm:translate-y-0 lg:flex-grow md:w-2/3 lg:pr-24 md:pr-16 flex flex-col md:items-start mb-16 md:mb-0 text-focus-in">
          <h3 class="text-redCustom text-center md:text-left ml-1 sm:text-4xl text-focus-in">TopTrading</h3>
          <h1 class="text-center md:text-left title-font text-5xl md:text-7xl lg:text-8xl mb-8 text-white border-b-4 pb-6 border-redCustom tracking-tighter">
            Academy</h1>
          <p class="leading-relaxed mb-8 text-lg text-center md:text-left text-gray-300">
            <span>Entra nel nostro mondo, ti accompagneremo verso la via del successo nel <span class="font-bold">trading online</span>.</span>
          </p>
          <button (click)="redirectToHome()"
          class="inline-flex rounded-full text-black font-semibold bg-redCustom shadow-yellow border-0 py-2 px-8 focus:outline-none hover:bg-redCustom-dark transition duration-300 ease-in-out transform hover:scale-105 text-lg shadow-redCustom items-center">
            <span class="pl-2">Vai al sito</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

