export const environment = {
  production: true,
  apiUrl: 'https://api-prod.top-tradingacademy.com',
  clientUrl: 'https://top-tradingacademy.com',
  storageUrl: 'https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/',
  allInclusiveStripePrice: 'https://buy.stripe.com/cN27tQbbQ3v0892cMR',
  smartVolumeStripePrice: 'https://buy.stripe.com/14k3dAdjY4z4892bIP',
  DiscordInviteLink: 'https://discord.gg/fVZ2CFPBqn',
  getResponseCustomFieldId: 'VgYrXL'
};
