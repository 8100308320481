<div class="relative font-urbanist flex h-screen">
  <div class="flex w-full bg-orange-blob-dark bg-cover bg-no-repeat">
    <div class="px-12 container mx-auto flex md:flex-row flex-col items-center">
      <div class="container mx-auto flex sm:px-10 md:flex-row flex-col items-center">
        <div class="transform translate-y-60 sm:translate-y-0 lg:flex-grow md:w-2/3 lg:pr-24 md:pr-16 flex flex-col md:items-start mb-16 md:mb-0 text-focus-in">
          <h3 class="text-redCustom text-center md:text-left ml-1 sm:text-4xl text-focus-in">TopTrading</h3>
          <h1 class="text-center md:text-left title-font text-5xl md:text-7xl lg:text-8xl mb-8 text-white border-b-4 pb-6 border-redCustom tracking-tighter">
            Academy</h1>
          <p class="leading-relaxed mb-8 text-lg text-center md:text-left text-gray-300">
            <span>Entra nel nostro mondo, ti accompagneremo verso la via del successo nel <span class="font-bold">trading online</span>.</span>
          </p>
        </div>
        <div class="hidden sm:flex">
          <div class="m-auto lg:max-w-lg lg:w-full shadow-darkhHeavy slide-in-blurred-right">
            <picture>
              <source srcset="../../../assets/images/home.webp" type="image/webp">
              <img class="object-cover object-center rounded-xl w-full h-full" width="300" height="300" alt="Simone e Marco, istruttori di Top Trading Academy." src="../../../assets/images/home.jpg">
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>