import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Plyr from 'plyr';
import {PlyrEvent} from 'plyr';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  @Input() facadeId: string
  @Input() vimeoLink: string
  @Input() dimensions: string[] = []
  @Input() label: string
  @Output() callback: EventEmitter<any> = new EventEmitter<any>()
  hoverActive: string = ''
  videoId: string = ''

  source: Plyr.Source[] = [{
    src: '',
    provider: 'vimeo'
  }]
  private _defaultDimensions: string[] = ['1920', '1080']

  constructor() { }

  ngOnInit(): void {
    // if the dimensions were not provided set them to
    // the default values
    if (this.dimensions.length == 0) {
      this.dimensions = this._defaultDimensions
    }
    // set the video source
    this.source[0].src = this.vimeoLink
    // get the videoId (numeric value)
    this.videoId = this.vimeoLink?.match(/\d+/g)[0]
  }

  /**
   * Renders the video, hiding the thumbnail.
   * @returns void
   */
  renderFacade = () => this.hoverActive = this.facadeId

  /**
   * Trigger a callback when the video ends
   * @param event plyr ended event
   */
  onVideoEnded(event: PlyrEvent) {
    this.callback.emit({
      event: event.detail.plyr.ended,
      label: this.label
    })
  }

}
