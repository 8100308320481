import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AllInclusiveComponent } from './pages/landing/all-inclusive/all-inclusive.component';
import { VolumasterComponent } from './pages/landing/volumaster/volumaster.component';
import { ThankYouComponent } from './pages/landing/thank-you/thank-you.component';
import { HomeComponent } from './pages/home/home.component';
import { CountdownPipe } from './helpers/pipes/countdown.pipe';
import { SEOService } from './helpers/services/seo.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { FooterComponent } from './components/footer/footer.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { WhatsappButtonComponent } from './components/whatsapp-button/whatsapp-button.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { SmartVolumeComponent } from './pages/landing/smart-volume/smart-volume.component';
import { ThankYouEsComponent } from './pages/landing-es/thank-you-es/thank-you-es.component';
import { AllInclusiveEsComponent } from './pages/landing-es/all-inclusive-es/all-inclusive-es.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TestingComponent } from './pages/landing/testing/testing.component';
import { GtagModule } from 'angular-gtag';
import { SmartVolumeNodateComponent } from './pages/landing/smart-volume-nodate/smart-volume-nodate.component';
import { AllInclusiveNodateComponent } from './pages/landing/all-inclusive-nodate/all-inclusive-nodate.component';
import { VolumasterVideoComponent } from './pages/landing/volumaster-video/volumaster-video.component';
import { AllInclusiveCorsoComponent } from './pages/landing/all-inclusive-corso/all-inclusive-corso.component';
import { PrevisionaliComponent } from "./pages/landing/previsionali/previsionali.component";
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { PlyrModule } from "ngx-plyr";
import { ObiettivoDuemezzoComponent } from './pages/landing/obiettivo-duemezzo/obiettivo-duemezzo.component';
import { LoadingAnimationSmallComponent } from './components/loading-animation-small/loading-animation-small.component';
import { LastSignalCardComponent } from './components/last-signal-card/last-signal-card.component';
import { LightboxModule } from "ngx-lightbox";
import { FormContattoComponent } from './components/form-contatto/form-contatto.component';
import { GenericThankYouComponent } from './pages/landing/generic-thank-you/generic-thank-you.component';
import { LaraAssistenteComponent } from './pages/lara-assistente/lara-assistente.component';
import { DisclaimerLiveComponent } from './pages/disclaimer-live/disclaimer-live.component';

@NgModule({
  declarations: [
    AppComponent,
    AllInclusiveComponent,
    VolumasterComponent,
    ThankYouComponent,
    SmartVolumeComponent,
    SmartVolumeNodateComponent,
    HomeComponent,
    CountdownPipe,
    FooterComponent,
    TermsConditionsComponent,
    DisclaimerComponent,
    WhatsappButtonComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    ThankYouEsComponent,
    AllInclusiveEsComponent,
    TestingComponent,
    AllInclusiveNodateComponent,
    VolumasterVideoComponent,
    AllInclusiveCorsoComponent,
    PrevisionaliComponent,
    VideoPlayerComponent,
    ObiettivoDuemezzoComponent,
    LoadingAnimationSmallComponent,
    LastSignalCardComponent,
    FormContattoComponent,
    GenericThankYouComponent,
    LaraAssistenteComponent,
    DisclaimerLiveComponent,
  ],
  imports: [
    GtagModule.forRoot({ trackingId: 'AW-661998525', trackPageviews: true }),
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PlyrModule,
    LightboxModule,
    ToastrModule.forRoot(), // ToastrModule added
  ],
  exports: [
    PlyrModule
  ],
  providers: [SEOService, HttpClientModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
