<div class="flex flex-col w-full justify-center text-center">
  <h1 class="text-2xl underline decoration-redCustom underline-offset-2">
    Form Contatto
  </h1>
  <p class="text-gray-400 text-sm py-2">
    Invia una mail a Top Trading Academy per qualsiasi informazione.
  </p>
</div>
<div class="px-8 m-auto w-full">
  <form (ngSubmit)="sendMail()" [formGroup]="form">
    <div class="grid grid-cols-6 space-y-6">
      <!--NOME-->
      <div class="col-span-6">
        <label class="label-std-left-validated">Nome Completo</label>
        <input [(ngModel)]="emailRequest.fullName"
               [value]="form.get('fullName').value | titlecase"
               class="input-std-dark"
               formControlName="fullName" name="fullName"
               placeholder="Mario Rossi"
               type="text">
        <p *ngIf="m.fullName.invalid && m.fullName.dirty"
           class="text-red-500 text-xs mt-2 text-left">
          Inserisci il tuo nome
        </p>
      </div>
      <!--EMAIL-->
      <div class="col-span-6">
        <label class="label-std-left-validated">Email</label>
        <input [(ngModel)]="emailRequest.email" class="input-std-dark"
               formControlName="email" name="email"
               placeholder="mario@mariorossi.it"
               type="email">
        <div *ngIf="m.email.invalid && m.email.touched" class="invalid-label">
          <p *ngIf="m.email.errors?.email">
            Email non valida
          </p>
          <p *ngIf="m.email.errors?.required">
            Email necessaria
          </p>
        </div>
      </div>
      <div class="col-span-6">
        <label class="label-std-left-validated">Messaggio</label>
        <textarea [(ngModel)]="emailRequest.message" class="input-std-dark"
                  formControlName="message" name="message"
                  placeholder="Scrivi quello che vorresti chiedere"
                  type="text">
              </textarea>
      </div>
    </div>
    <div class="flex mx-auto my-12 justify-center">
      <button class="button-small"
              type="submit">
        <div *ngIf="!loading" class="flex flex-row">
          <span>Invia Messaggio</span>
          <svg class="inline-flex my-auto ml-2 h-4 w-4" fill="none" stroke="currentColor" stroke-width="2"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
              stroke-linecap="round"
              stroke-linejoin="round"/>
          </svg>
        </div>
        <div *ngIf="loading" class="flex flex-row">
          <span>Carico</span>
          <app-loading-animation-small class="inline-flex my-auto ml-2"></app-loading-animation-small>
        </div>
      </button>
    </div>
  </form>
</div>