import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../helpers/services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  website = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: 'https://top-tradingacademy.com',
    name: 'Top Trading Academy',
    contactPoint: {
      '@type': 'ContactPoint',
      availableLanguage: 'IT',
      email: 'info@top-tradingacademy.com',
      contactType: 'Customer Service'
    }
  };

  organization = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://top-tradingacademy.com',
    name: 'Top Trading Academy',
    contactPoint: {
      '@type': 'ContactPoint',
      availableLanguage: 'IT',
      email: 'info@top-tradingacademy.com',
      contactType: 'Customer service'
    }
  }


  constructor(private seo: SEOService) {
    this.seo.updateTitle('Top Trading Academy')
    this.seo.updateDescription('Top Trading Academy è il punto di riferimento per tutte le persone che vogliono imparare a fare trading online, oppure che vogliono migliorare le proprie performance ma non sanno come fare.')
    this.seo.updateOgUrl('https://top-tradingacademy.com')
  }
}
