import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FacebookPixelsService } from '../../../helpers/services/facebookPixels.services';
import { GetResponseService } from '../../../helpers/services/getResponse.service';
import { SEOService } from '../../../helpers/services/seo.service';
import { WebinarManager } from '../../../helpers/services/webinarManager.service';
import { environment } from 'src/environments/environment';
import { AddContactOptions } from '../../../helpers/models/addContact.getResponse.model';
import { LeadsService } from 'src/app/helpers/services/leads.service';
import { Lead } from "../../../helpers/models/lead.model";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { EmailPattern } from "../../../helpers/patterns/validators.patterns";
import { Detail } from "../../../helpers/models/details.model";

const getResponseValues: AddContactOptions = {
  name: '',
  campaign: {
    campaignId: ''
  }, // Taken from URL (Route params)
  email: '',
  dayOfCycle: '0',
  ipAddress: '',
  customFieldValues: []
}

@Component({
  selector: 'app-all-inclusive-nodate',
  templateUrl: './all-inclusive-nodate.component.html',
  styleUrls: ['./all-inclusive-nodate.component.scss']
})
export class AllInclusiveNodateComponent implements OnInit {

  imageToDisplay: string = '../../../../assets/images/webinar/pari.jpg'
  titleToDisplay: string = 'Indicatore Bande di Migliorino'
  phone: string = ''

  lead: Lead = {
    leadId: 0,
    fullName: '',
    email: '',
    phone: '',
    whichCourses: '',
    privacyTrattamento: true,
    privacyContatto: true,
    privacyThirdParty: true,
  }
  request: AddContactOptions = getResponseValues

  isEmailValid: boolean = true;
  isPhoneValid: boolean = true;
  isNameValid: boolean = true;
  loading: boolean = false;

  form: FormGroup

  constructor(
    private formBuilder: FormBuilder,
    private leadService: LeadsService,
    private renderer2: Renderer2,
    private facebookPixelService: FacebookPixelsService,
    private webinarManager: WebinarManager,
    private router: Router,
    private getResponse: GetResponseService,
    private toastr: ToastrService,
    private seo: SEOService,
    private route: ActivatedRoute
  ) {
    // Facebook & Google Pixels
    this.seo.updateTitle('Webinar Gratuito di Trading Online')
    this.seo.updateOgLocale()
    this.seo.updateOgType('article')
    this.seo.updateOgImage('https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/anteprima-link-webinar.jpg')
    this.seo.updateDescription(`La mia strategia in un Corso. Guarda il WEBINAR GRATUITO, ti spiegherò un indicatore formidabile e ti svelerò la mia strategia operativa con cui opero ogni giorno.`)
    this.route.params.subscribe(params => {
      this.request.campaign.campaignId = params['socialId']
      this.request.dayOfCycle = params['dayOfCycle'] || '0'
    })
    this.form = this.formBuilder.group({
      name: new FormControl(this.request.name, [Validators.required]),
      email: new FormControl(this.request.email, [Validators.required, Validators.pattern(EmailPattern)]),
      phone: new FormControl(this.lead.phone, [Validators.required]),
      privacyTrattamento: new FormControl(this.lead.privacyTrattamento, [Validators.requiredTrue]),
      privacyContatto: new FormControl(this.lead.privacyContatto, [Validators.required]),
      privacyThirdParty: new FormControl(this.lead.privacyThirdParty, [Validators.required])
    })
  }

  async ngOnInit() {
    this.getResponse.getClientIp()
      .then((res: any) => {
        if (res) this.request.ipAddress = res.ip
      }).catch(() => {
      this.request.ipAddress = '0.0.0.0'
    })
    await this.facebookPixelService.injectScrollTracker(this.renderer2)
  }

  get m() {
    return this.form.controls
  }

  // GET RESPONSE + LEAD
  async onSubmit() {
    if (this.form.valid) {
      if (this.request.customFieldValues.length === 0) {
        this.request.customFieldValues.push({
          customFieldId: environment.getResponseCustomFieldId,
          value: [this.lead.phone]
        })
      }
      this.loading = true;
      await this.getResponse.addContactV2(this.request)
        .then(async () => {
          this.lead.email = this.request.email
          this.lead.fullName = this.request.name
          this.lead.whichCourses= 'all-inclusive-nodate'
          await this.leadService.createLead(this.lead)
            .then(async () => {
              this.toastr.success(`Registrazione avvenuta con successo! Goditi il Corso`, 'Goditi il corso')
              await this.navigateToThankYou()
          }).catch(async () => {
              this.toastr.success(`Registrazione avvenuta con successo! Goditi il Corso`, 'Goditi il corso')
              await this.navigateToThankYou()
          })
        })
        .catch((err) => {
          console.log(err.error.errors[0].message)
          this.toastr.warning(err.error.errors[0].message, 'Registrazione non avvenuta')
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.loading = false
      this.toastr.warning('Tutti i campi sono obbligatori', 'Attenzione')
    }
  }

  async navigateToThankYou() {
    const params = {
      name: this.request.name,
      email: this.request.email,
      phone: this.lead.phone
    }
    await this.router.navigate(['/webinar/thankyou/all-inclusive-nodate'], { queryParams: params })
  }
}