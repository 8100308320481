<div class="flex justify-center min-h-screen w-full h-full sm:block sm:p-0 bg-customDark bg-cover bg-no-repeat text-white">
    <div class="container mx-auto pb-8  px-8">
      <div class="flex flex-col text-center pt-24 font-bold">
        <h1 class="text-3xl font-bold underline decoration-redCustom text-center">
            Disclaimer ed Informazioni sul servizio Operatività Live All-Inclusive
        </h1>
      </div>
      <div class="flex flex-col mx-auto max-w-6xl">

        <h1 class="text-xl font-bold py-6">
            Disclaimer Operatività Live
        </h1>

        <div class="space-y-2">
          <p>
            Il servizio “Operatività Live” del corso New-All-Inclusive ha esclusiva finalità educativa e didattica, 
            il suo contenuto non costituisce alcuna forma di “consulenza o raccomandazione di investimento” o “incentivo 
            all’investimento” né in forma esplicita o implicita.          
          </p>
          <p>
            Il servizio viene erogato esclusivamente ad Utenti che hanno acquistato il corso New-All-Inclusive, affinché 
            possano osservare l’operatività di un trader che opera attraverso l’utilizzo della  strategia spiegata all’interno 
            del corso. 
          </p>
          <p>
            Tutte le operazioni effettuate durante le dirette Discord, fanno parte di percorsi formativi ed educativi che 
            illustrano come operare con il “trading” esclusivamente per scopi didattici e formativi.
          </p>
          <p>
            Lo scopo di questo sito è diffondere la cultura che il trading e gli investimenti sono attività rischiose, non 
            adatte a tutti, che vanno affrontate con conoscenza e preparazione. La formazione e l’esercizio sono l’obbiettivo 
            primario degli autori del sito.
          </p>
          <p>
            Qualsiasi concetto, notifica, nozione, parere o tecnica espressi all’interno delle dirette sono semplici pareri 
            personali degli autori, di carattere generale, non hanno lo scopo di prestare consigli operativi personalizzati, 
            di acquisto e/o vendita, né raccomandazioni personalizzate riguardo una o più operazioni relative ad un determinato 
            strumento finanziario. Nessuna opinione espressa riguardante investimenti o strategie di investimento può pertanto 
            considerarsi adeguata alle caratteristiche di una specifica persona in merito alla sua conoscenza ed esperienza del 
            trading online ed alla sua situazione finanziaria.
          </p>
          <p>
            Ogni utente è personalmente ed esclusivamente responsabile di un utilizzo del servizio diverso da quello didattico 
            con finalità di analisi, esercizio e formazione. Per nessun motivo TTG può essere considerata responsabile per utilizzi 
            diversi, da parte dell’utente, rispetto a quelli delineati nel presente articolo che, se intrapresi, sono di “esclusiva 
            iniziativa” e responsabilità dell’Utente stesso. Per nessun motivo TTgames s.r.l e/o i propri soci possono essere 
            considerati responsabili per eventuali perdite che l’Utente abbia subito nel tentativo di replicare l’operatività 
            dell’autore, anche in considerazione del fatto che risultati passati non sono garanzia di risultati futuri. 
          </p>
      </div>
  
        <h1 class="text-xl font-bold py-6">
            Info Operatività Live
        </h1>
        <div class="space-y-2">
          <p>
            Il servizio “Operatività Live” del corso New-All-Inclusive è un servizio con esclusiva finalità educativa e didattica, 
            che permette di vedere in tempo reale l’operatività di un trader professionista della Top-Trading Academy. Il trader 
            opera sul mercato del Future Dax, con un capitale di 40.000,00 euro ed utilizza contratti da micro-lotti del valore di 
            1,00 euro a punto (micro-Dax) o mini-lotti del valore di 5 euro a punto (mini-Dax).
          </p>
          <p>
            Prima dell’inizio della diretta, viene inoltrata una notifica, sul Canale “Chat” del ramo Discord New-All-Inclusive 
            (del nostro server Discord) circa 5 minuti prima dell’inizio di una Live, la notifica avvisa che sta per iniziare una 
            Diretta Live.
          </p>
          <p>
            Le Dirette Live avvengono sul Canale Discord denominato “Operatività Live”, una volta chiusa la diretta la registrazione 
            viene postata sulla chat del canale stesso così che sia visibile in differita dagli Utenti iscritti al servizio.
          </p>
          <p>
            All’interno delle Dirette Live il nostro Trader fa vedere integralmente la propria operatività, delineando i propri 
            prezzi di ingresso, Stop Loss fissati e Target ipotizzati.
          </p>
          <p>
            Il servizio comprende, Video di valutazione del mercato, Diretta per analisi e definizione punto di ingresso previsto, 
            Diretta di apertura posizione, Diretta di gestione del trade, Diretta di chiusura del trade. 
          </p>
          <p>
            L’operatività si svolge principalmente sul ciclo di 4 giorni ed a volte sul ciclo di 2 giorni, esclusivamente sul 
            Future Dax.
          </p>
          <p>
            Il prezzo del servizio è di 60,00 euro al mese e può essere acquistato solo da studenti che hanno acquistato il corso 
            New-All-Inclusive.
          </p>
        </div>
      </div>
    </div>
  </div>