import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-animation-small',
  templateUrl: './loading-animation-small.component.html',
  styleUrls: ['./loading-animation-small.component.scss']
})
export class LoadingAnimationSmallComponent {

  @Input() textColor: string = 'black'

  constructor() {
  }

  getStyles() {
    return {
      'border-color': this.textColor,
      'border-top-color': 'transparent'
    }
  }
}
