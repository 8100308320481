<div class="flex flex-col w-full h-full min-h-screen bg-customDark text-white">
  <div class="flex flex-col m-auto space-y-4 text-focus-in">
    <h1 class="mx-auto text-5xl text-white underline decoration-redCustom underline-offset-4">
      Grazie per l'iscrizione
    </h1>
    <p class="mx-auto">
      Grazie per l'iscrizione a {{courseName | titlecase }}!
    </p>
  </div>
  <div class="flex flex-col w-full sm:w-1/3 m-auto">
    <app-form-contatto></app-form-contatto>
  </div>
</div>