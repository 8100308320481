import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lead } from '../models/lead.model';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LeadsService {

  constructor(private http: HttpClient) {}

  createLead(lead: Lead): Promise < Lead > {
    return this.http.post < Lead > (`${environment.apiUrl}/Leads/CreateLead`, lead)
      .toPromise()
  }
}