import { Injectable } from "@angular/core";
import { PhoneValidator } from "../models/phoneValidation.model";
import { WebinarResponse } from "../models/webinarResponse.model";

const webinarHour = 20;
const dayOfWeek: string[] = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'],
  months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
const dayOfWeekEs: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
  monthsEs = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

@Injectable({ providedIn: 'root' })
export class WebinarManager {

  // DATE MANIPULATION
  // es = true --> spagnolo, esaDay = true --> cambio giorno per il webinar in spagnolo
  whichDateNext(es ? : boolean, esDay ? : boolean, testing ? : boolean): WebinarResponse {
    let reponse: WebinarResponse = {
      nextWebinarDateString: '',
      nextWebinarDateCountDown: '',
      imageToDisplay: '',
      titleToDisplay: ''
    }
    let whichNextDay = es ? 1 : 3; // 0 = Lunedì, 1 = Martedì ...
    if (testing) whichNextDay = 4;
    var date = new Date();
    // trovo la differenza tra oggi e il giorno desiderato
    var difference = date.getDay() - whichNextDay;
    // se la differenza è : 1 allora prendo 7 meno la differenza, altrimenti prendo la differenza in negativo
    difference = difference > 0 ? 7 - difference : -difference;
    // imposto la data prendendo la data di oggi piu il calcolo appena trovato
    date.setDate(date.getDate() + difference);
    // imposto l'orario della nuova data
    es ? date.setHours(19, 0, 0) : date.setHours(20, 0, 0)
    if (testing) date.setHours(20, 0, 0);
    // imposto il giorno aggiungendo uno perche js vuole cosi
    let dd = date.getDate() + 1;
    // controllo nullabile
    dd < 0 ? dd = 0 + dd : null;
    // prendo anno
    var yyyy = date.getFullYear();
    // prendo il giorno letterale usando l'indice corrispondente all'array
    var DD = es ? dayOfWeekEs[date.getDay()] : dayOfWeek[date.getDay()];
    // stessa cosa per il mese
    var mm = es ? monthsEs[date.getMonth()] : months[date.getMonth()];
    // costruisco la stringa per la data futura
    es
      ? reponse.nextWebinarDateString = `${DD} ${dd} ${mm} ${yyyy} \u00B7 A las ${date.getHours()}:00`
      : reponse.nextWebinarDateString = `${DD} ${dd} ${mm} ${yyyy} \u00B7 Ore ${date.getHours()}:00`
    // console.log(reponse.nextWebinarDateString)
    date.setDate(date.getDate() + 1)
    reponse.nextWebinarDateCountDown = date.toISOString()
    // console.log(reponse.nextWebinarDateCountDown)
    // Ottengo il numero di settimana nel mese + 1 perché devo cambiare per essere giusto la prossima settimana
    let weekNumber = this.getWeekOfMonth(date)
    // console.log(weekNumber)
    if (!esDay) {
      if (weekNumber % 2 == 0) {
        reponse.imageToDisplay = '../../../../assets/images/webinar/pari.jpg'
        reponse.titleToDisplay = 'Indicatore Bande di Migliorino'
      } else {
        reponse.imageToDisplay = '../../../../assets/images/webinar/dispari.jpg'
        reponse.titleToDisplay = 'Indicatore DNA'
      }
    } else {
      if (weekNumber % 2 == 0) {
        reponse.imageToDisplay = '../../../../assets/images/webinar/pari.jpg'
        reponse.titleToDisplay = 'El indicador Bandas de Migliorino'
      } else {
        reponse.imageToDisplay = '../../../../assets/images/webinar/dispari.jpg'
        reponse.titleToDisplay = 'El indicador ADN'
      }
    }

    return reponse;
  }

  addDays(numOfDays: number, date = new Date()) {
    date.setDate(date.getDate() + numOfDays);
    return date;
  }

  // funziona che ottiene il numero di settimana rispetto al mese
  getWeekOfMonth(date: Date) {
    var firstWeekDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay() - 1;
    if (firstWeekDay < 0) firstWeekDay = 6;
    var offSetDate = date.getDate() + firstWeekDay - 1;
    return Math.floor(offSetDate / 7);
  }

  // Validations
  validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePhone(phone: string): PhoneValidator {

    let phoneValidator: PhoneValidator = {
      isPhoneValid: true,
      correctedPhone: ''
    }

    const re = /^\d+$/;
    let isValid = re.test(String(phone).toLowerCase());
    let noStrings
    if (!isValid) {
      noStrings = phone.replace(/[^\d.-]/g, '')
      phoneValidator.isPhoneValid = re.test(String(noStrings))
      if (phoneValidator.isPhoneValid) phoneValidator.correctedPhone = noStrings
    } else phoneValidator.isPhoneValid = isValid
    return phoneValidator
  }

  validateName(name: string): boolean {
    // let titleCaseName = this.convertToTitleCase(name)
    const re = /^[a-z]+(?: [a-z]+)+$/i;
    return re.test(String(name).toLowerCase());
  }

  convertToTitleCase(name: string): string {
    return name.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
}
