import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent {
  constructor(private location: Location) { }

  back = () => this.location.back()
}
