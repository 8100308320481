<div class="flex flex-col w-full text-center bg-customDark bg-cover bg-no-repeat">
  <div class="flex max-w-xs bg-redCustom shadow-darkhHeavy translate-y-8 m-auto rounded-full">
    <img class="rounded-full" src="../../../../assets/images/webinar/simo_webinar.png" width="350" height="350" alt="Simone Rubessi, host del Webinar"
      title="Simone Rubessi, trader e host del Webinar">
  </div>
  <div class="flex flex-col rounded-tl-2xl rounded-tr-2xl text-black bg-redCustom m-auto w-full">
    <h1 *ngIf="!isGeneralNoDate" class="m-auto mt-12 text-6xl font-bold text-focus-in">Grazie!</h1>
    <h1 *ngIf="isGeneralNoDate" class="m-auto mt-12 text-6xl font-bold text-focus-in">Grazie per esserti iscritto!</h1>
    <h2 *ngIf="!isGeneralNoDate && !isVolumasterVideo" class="m-auto pt-2 px-4 text-lg md:text-xl font-semibold text-focus-in">A breve riceverai un link sulla tua mail per accedere al corso.</h2>
    <h2 *ngIf="isGeneralNoDate && !isNoDateAllInclusive && !isVolumasterVideo" class="m-auto py-4 px-4 text-lg md:text-xl font-semibold text-focus-in">Goditi il Corso.</h2>
    <!-- VOLUMASTER VIDEO -->
    <h2 *ngIf="isGeneralNoDate && isVolumasterVideo" class="m-auto py-4 px-4 text-lg md:text-xl font-semibold text-focus-in">
      Grazie per la tua fiducia e buona visione!
    </h2>
    <h2 *ngIf="isNoDateAllInclusive" class="m-auto py-4 px-4 text-lg md:text-xl font-semibold text-focus-in">Goditi il Corso.</h2>
    <div *ngIf="shouldDisplayAllInclusiveVideo" class="flex flex-col m-auto max-w-6xl w-full px-8 md:px-24">
      <h1 *ngIf="!isGeneralNoDate" class="mx-auto text-2xl md:text-3xl font-bold pt-4 pb-4 text-focus-in">Ecco cosa ti aspetta nel corso</h1>
      <div class="aspect-w-16 aspect-h-9 shadow-darkhHeavy">
        <iframe loading="lazy" src="https://player.vimeo.com/video/616880907" style="overflow:hidden;height:100%;width:100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
    <!-- SMART VOLUME NO DATE -->
    <div *ngIf="isNoDateSmartVolume" class="flex flex-col m-auto max-w-6xl w-full px-8 md:px-24">
      <div class="aspect-w-16 aspect-h-9 shadow-darkhHeavy">
        <iframe loading="lazy" src="https://player.vimeo.com/video/725280386" style="overflow:hidden;height:100%;width:100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <!-- Acquista ora -->
      <div class="flex flex-col w-full m-auto">
        <p [@fade]="shouldShowPurchaseButton" *ngIf="shouldShowPurchaseButton" class="mx-auto text-black font-semibold text-xl mt-6">Clicca per acquistare con carta di credito o bonifico.</p>
        <a [@fade]="shouldShowPurchaseButton" *ngIf="shouldShowPurchaseButton" class="flex flex-col mx-auto" href="{{ productStripeUrl }}">
          <button
            class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-2 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
            <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">
              Acquista
            </span>
          </button>
        </a>
        <!-- PAYPAL -->
        <div class="flex flex-col mx-auto mt-4">
          <p [@fade] *ngIf="shouldShowPurchaseButton" class="mx-auto text-black font-semibold text-xl mb-2">Clicca per acquistare con PayPal.</p>
          <div class="mx-auto hover:scale-105 transition duration-300 ease-in-out" #paypalRef></div>
        </div>
      </div>
    </div>
    <!-- ALL INCLUSIVE NO DATE -->
    <div *ngIf="isNoDateAllInclusive" class="flex flex-col m-auto max-w-6xl w-full px-8 md:px-24">
      <div class="aspect-w-16 aspect-h-9 shadow-darkhHeavy">
        <iframe loading="lazy" src="https://player.vimeo.com/video/684301085" style="overflow:hidden;height:100%;width:100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="flex flex-col w-full m-auto">
        <!-- Acquista ora -->
        <p [@fade] *ngIf="shouldShowPurchaseButton" class="mx-auto text-black font-semibold text-xl mt-6">Clicca per acquistare con carta di credito o bonifico.</p>
        <a [@fade] *ngIf="shouldShowPurchaseButton" class="flex flex-col mx-auto" href="{{ productStripeUrl }}">
          <button
            class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-2 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
            <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">
              Acquista
            </span>
          </button>
        </a>
        <!-- PAYPAL -->
        <div class="flex flex-col mx-auto mt-4">
          <p [@fade] *ngIf="shouldShowPurchaseButton" class="mx-auto text-black font-semibold text-xl mb-2">Clicca per acquistare con PayPal.</p>
          <div class="mx-auto hover:scale-105 transition duration-300 ease-in-out" #paypalRef></div>
        </div>
      </div>
      <div [@fade] *ngIf="shouldShowPurchaseButton" class="flex flex-col mx-auto my-4">
        <span class="max-w-2xl text-lg mt-4">
          Inserisci il coupon <span class="font-bold">SCONTO300</span> per ottenere €300 di sconto.
        </span>
      </div>
    </div>
    <!-- VOLUMASTER VIDEO -->
    <div *ngIf="isVolumasterVideo" class="flex flex-col m-auto max-w-6xl w-full px-8 md:px-24">
      <div class="aspect-w-16 aspect-h-9 shadow-darkhHeavy">
        <iframe loading="lazy" src="https://player.vimeo.com/video/632956465" style="overflow:hidden;height:100%;width:100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
    <!-- ALL INCLUSIVE -->
    <div *ngIf="shouldDisplayAllInclusiveVideo" class="flex flex-col md:hidden m-auto mt-4 text-focus-in">
      <svg xmlns="http://www.w3.org/2000/svg" class="animate-bounce h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
      </svg>
    </div>
  </div>
</div>
<div class="flex flex-col bg-redCustom text-black w-full py-16 m-auto text-center" [ngClass]="{'h-screen py-0': !shouldDisplayAllInclusiveVideo}">
  <h2 *ngIf="isGeneralNoDate" class="mx-auto py-4 px-4 text-lg md:text-xl font-semibold text-focus-in">Manda un messaggio per qualsiasi tipo di informazione.</h2>
  <div *ngIf="isGeneralNoDate" class="flex flex-col mx-auto pb-8">
    <form (ngSubmit)="onSubmit()" id="form-iscrizione-webinar">
      <div class="flex flex-col gap-y-4">
        <div class="flex flex-col mx-auto w-80 md:w-96">
          <label for="name" class="block text-sm font-bold">Nome <span *ngIf="!isNameValid">*</span></label>
          <input autocomplete="off" type="text" id="name" name="name" [(ngModel)]="request.name"
            class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
          <label *ngIf="!isNameValid" class="text-black font-bold pt-2" for="phoneError">Attenzione, Nome obbligatorio.</label>
        </div>
        <div class="flex flex-col mx-auto w-80 md:w-96">
          <label for="email" class="block text-sm font-bold">Email <span *ngIf="!isEmailValid">*</span></label>
          <input autocomplete="off" type="email" id="email" name="email" [(ngModel)]="request.email" (change)="validateEmail(request.email)"
            class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
          <label *ngIf="!isEmailValid" class="text-black font-bold pt-2" for="phoneError">Attenzione, Email non valida.</label>
        </div>
        <div class="flex flex-col mx-auto w-80 md:w-96">
          <label for="phone" class="block text-sm font-bold">Cellulare <span *ngIf="!isPhoneValid">*</span></label>
          <input autocomplete="off" type="text" id="phone" name="phone" [(ngModel)]="request.phone"
            class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
          <label *ngIf="!isPhoneValid" class="text-black font-bold pt-2" for="phoneError">Attenzione, telefono non valido.</label>
        </div>
        <div class="flex flex-col mx-auto w-80 md:w-96">
          <label for="phone" class="block text-sm font-bold">Messaggio</label>
          <textarea autocomplete="off" type="text" id="message" name="message" [(ngModel)]="request.message"
            class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
          </textarea>
        </div>
        <label class="text-black m-auto font-bold" for="Information">Attenzione: tutti i campi sono obbligatori.</label>
      </div>
      <button type="submit"
        class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
        <span *ngIf="!loading" class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">Invia</span>
        <span *ngIf="loading" class="text-black text-xl sm:text-2xl lg:text-xl font-bold">Carico...</span>
        <div *ngIf="loading" class="ml-4 mt-1 animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-black">
        </div>
      </button>
    </form>
  </div>
</div>