import { Component } from '@angular/core';
import { Location } from '@angular/common';


@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent {

  constructor(private location: Location) { }

  back = () => this.location.back()
}
