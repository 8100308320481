<div class="fixed z-40">
  <a href="https://wa.me/{{phone}}" target="_blank" rel="noopener">
    <div class="bg-redCustom w-16 h-16 rounded-full fixed bottom-5 right-5 shadow-darkhHeavy grid place-items-center group">
      <picture class="group-hover:scale-110 transition duration-150">
        <source srcset="../../../../assets/images/webinar/whatsapp-logo.webp" type="image/webp" width="32" height="32"
          alt="WhatsApp Logo"
          title="WhatsApp Logo">
        <img src="../../../../assets/images/webinar/whatsapp-logo.png" width="32" height="32"
          alt="WhatsApp Logo"
          title="WhatsApp Logo">
      </picture>
    </div>
  </a>
</div>