import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SEOService } from 'src/app/helpers/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-thank-you-es',
  templateUrl: './thank-you-es.component.html',
  styleUrls: ['./thank-you-es.component.scss']
})
export class ThankYouEsComponent {

  discordInviteUrl: string = environment.DiscordInviteLink
  shouldDisplayVideo: boolean = true;
  
  constructor(private seo: SEOService, private route: ActivatedRoute) {
    this.seo.updateTitle("Gracias por registrarte")
    this.route.params.subscribe(params => {
      if (params['courseId'] == 'volumaster' || params['courseId'] == 'smart-volume') this.shouldDisplayVideo = false  
    })
  }

}
