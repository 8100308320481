import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2 } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class FacebookPixelsService {

    constructor(@Inject(DOCUMENT) private _document: Document) {}

    async injectScrollTracker(renderer2: Renderer2) {
        let script = renderer2.createElement('script')
        script.type = 'text/javascript'

        script.text = 
        `var i30 = i75 = iIC = !1;
        document.addEventListener("scroll", function(t) {
          var e = (window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop) / (document.body.scrollHeight - (window.innerHeight || document.documentElement
              .clientHeight || document.body.clientHeight)),
            o = Math.round(100 * e);
          30 != o || i30 || (fbq("track", "ViewContent"), i30 = !0), 75 != o || i75 || (fbq("track", "InitiateCheckout"), i75 = !0)
        });`

        renderer2.appendChild(this._document.body, script);
    }

    async injectConversionThankYouCourse(renderer2: Renderer2) {
      let script = renderer2.createElement('script')
      script.type = 'text/javascript'

      script.text = `fbq('track', 'CompleteRegistration');`

      renderer2.appendChild(this._document.body, script)
    }

    async injectAddToCartTracker(renderer2: Renderer2, amount: number) {
      let script = renderer2.createElement('script')
      script.type = 'text/javascript'

      script.text = `fbq('track', 'AddToCart', {currency: "EUR", value: ${amount}.00});`

      renderer2.appendChild(this._document.body, script)
    }
}