<div class="flex flex-col w-full h-screen text-center bg-customDark bg-cover bg-no-repeat">
  <div class="flex max-w-xs bg-redCustom shadow-darkhHeavy translate-y-8 m-auto rounded-full">
    <img class="rounded-full" src="../../../../assets/images/webinar/simo_webinar.png" width="350" height="350" alt="Simone Rubessi, host del Webinar"
      title="Simone Rubessi, trader e host del Webinar">
  </div>
  <div class="flex flex-col rounded-tl-2xl rounded-tr-2xl text-black bg-redCustom mx-auto w-full h-3/4">
    <div class="flex flex-col">
      <h1 class="m-auto mt-12 text-6xl font-bold text-focus-in">¡Gracias!</h1>
      <h2 class="m-auto pt-2 px-4 text-lg md:text-xl font-semibold text-focus-in">En breve recibirás en tu correo electrónico un enlace para acceder al webinar.</h2>
      <div class="flex flex-col">
        <h3 class="m-auto pt-8 text-xl md:text-2xl font-semibold text-focus-in">Suscríbete a nuestro canal de Telegram para ver nuestros videos operativos</h3>
        <a href="{{ discordInviteUrl }}" target="_blank" rel="nofollow">
          <button
            class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-4 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center text-focus-in">
            <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold">Suscríbete a nuestro canal de Telegram</span>
          </button>
        </a>
      </div>
    </div>
  </div>
</div>