import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { HomeComponent } from './pages/home/home.component';
import { AllInclusiveEsComponent } from './pages/landing-es/all-inclusive-es/all-inclusive-es.component';
import { ThankYouEsComponent } from './pages/landing-es/thank-you-es/thank-you-es.component';
import { AllInclusiveCorsoComponent } from './pages/landing/all-inclusive-corso/all-inclusive-corso.component';
import { AllInclusiveNodateComponent } from './pages/landing/all-inclusive-nodate/all-inclusive-nodate.component';
import { AllInclusiveComponent } from './pages/landing/all-inclusive/all-inclusive.component';
import { SmartVolumeNodateComponent } from './pages/landing/smart-volume-nodate/smart-volume-nodate.component';
import { SmartVolumeComponent } from './pages/landing/smart-volume/smart-volume.component';
import { TestingComponent } from './pages/landing/testing/testing.component';
import { ThankYouComponent } from './pages/landing/thank-you/thank-you.component';
import { VolumasterVideoComponent } from './pages/landing/volumaster-video/volumaster-video.component';
import { VolumasterComponent } from './pages/landing/volumaster/volumaster.component';
import { PrevisionaliComponent } from "./pages/landing/previsionali/previsionali.component";
import { ObiettivoDuemezzoComponent } from "./pages/landing/obiettivo-duemezzo/obiettivo-duemezzo.component";
import { GenericThankYouComponent } from "./pages/landing/generic-thank-you/generic-thank-you.component";
import { LaraAssistenteComponent } from './pages/lara-assistente/lara-assistente.component';
import { DisclaimerLiveComponent } from './pages/disclaimer-live/disclaimer-live.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'webinar/testing/:socialId/:dayOfCycle', component: TestingComponent },
   // Route che si rinnova settimanalmente
  { path: 'webinar/all-inclusive/:socialId/:dayOfCycle', component: AllInclusiveComponent },
  { path: 'webinar/all-inclusive-corso/:socialId/:dayOfCycle', component: AllInclusiveCorsoComponent },
  // Route fissa
  { path: 'webinar/all-inclusive-nodate/:socialId/:dayOfCycle', component: AllInclusiveNodateComponent },
  { path: 'webinar/es/all-inclusive/:socialId/:dayOfCycle', component: AllInclusiveEsComponent },
   // Route che si rinnova settimanalmente
  { path: 'webinar/smart-volume/:socialId/:dayOfCycle', component: SmartVolumeComponent },
  // Route fissa
  { path: 'webinar/obiettivo-250/:socialId/:dayOfCycle', component: ObiettivoDuemezzoComponent },
  { path: 'webinar/smart-volume-nodate/:socialId/:dayOfCycle', component: SmartVolumeNodateComponent },
  { path: 'webinar/volumaster/:socialId/:dayOfCycle', component: VolumasterComponent },
  { path: 'webinar/volumaster-video/:socialId/:dayOfCycle', component: VolumasterVideoComponent },
  { path: 'webinar/previsionali/:socialId/:dayOfCycle', component: PrevisionaliComponent },
  { path: 'webinar/thankyou/:courseId', component: ThankYouComponent },
  { path: 'webinar/thank-you/:courseId', component: GenericThankYouComponent },
  { path: 'webinar/es/thankyou/:courseId', component: ThankYouEsComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'lara-assistente', component: LaraAssistenteComponent },
  { path: 'disclaimer-live', component: DisclaimerLiveComponent },

  { path : '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64], // [x, y]
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
