<div class="justify-center text-white min-h-screen w-full h-full bg-customDark pt-12 scroll-smooth">
  <section
    class="flex flex-col lg:flex-row pt-12 m-auto w-full xl:w-5/6 lg:space-x-8 bg-cover bg-no-repeat items-center flex-row xl:gap-x-8">
    <!-- TESTO -->
    <div class="flex m-auto flex-col items-center lg:max-w-1/2">
      <div class="flex flex-col">
        <h2
          class="flex flex-col flex-nowrap lg:flex-row px-4 pb-2 text-sm text-center lg:text-left text-gray-300 uppercase font-bold">
          <span class="text-redCustom">webinar gratuito</span>
          <span class="hidden lg:block px-2">&#183;</span>
          <span>{{ nextWebinarDateString }}</span>
        </h2>
        <h1
          class="px-4 pb-2 md:pb-4 text-center font-bold lg:text-left text-5xl md:text-6xl">
          <span>Obiettivo 250</span><br>
        </h1>
        <h2 class="px-4 pb-4 md:pb-8 text-center lg:text-left text-2xl md:text-3xl font-bold">
          <span>Come fare il 250% di profitto annuo.</span>
        </h2>
      </div>
      <h2
        class="py-4 my-2 px-4 mx-auto  text-xl max-w-4xl text-left border-l-2 text-gray-300 border-gray-300 ml-8 md:ml-16">
        Partecipa al webinar e avrai <span class="font-bold">15 giorni omaggio</span> del nostro servizio di notifiche
        delle operazioni effettuate da un nostro trader professionista <span
        class="font-bold">per vedere in diretta</span> come opera!
      </h2>
      <div
        class="flex flex-col w-full sm:w-2/3 lg:w-full bg-redCustom rounded-lg mx-2 md:mx-4 my-8 px-4 text-black">
        <div class="flex flex-wrap mx-auto justify-center">
          <h3 class="flex flex-wrap mt-8 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 pb-2 md:pb-1 md:mr-2 h-8 w-8" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
            </svg>
            <span class="text-base md:text-xl font-semibold">{{ nextWebinarDateString }}</span>
          </h3>
        </div>
        <button [routerLink]="['/webinar', 'obiettivo-250', request.campaign.campaignId, request.dayOfCycle]"
                fragment='form-iscrizione-webinar' routerLinkActive="router-link-active"
                class="button-std bg-greenWebinar hover:bg-greenWebinar-dark justify-center my-4">
          <span class="text-xl sm:text-2xl lg:text-3xl font-bold">Prenota il tuo posto gratis</span>
        </button>
        <div class="flex flex-col m-auto pb-8 text-center">
          <h1 class="mx-auto sm:text-lg mt-2 tracking font-semibold capitalize">
            I posti sono solo 1000, non perdere altro tempo
          </h1>
          <h2 class="mx-auto text-xl sm:text-2xl mt-2 tracking font-bold">
            {{ nextWebinarDateCountDown | countdown | async }}
          </h2>
        </div>
      </div>
    </div>
    <!-- VIDEO -->
    <div class="flex w-full h-full flex-col relative m-auto mx-2">
      <div class="flex w-full h-full mx-auto overflow-hidden px-4 md:px-0">
        <app-video-player
          class="w-full h-full"
          [facadeId]="'obiettivo-350-preview'"
          [vimeoLink]="'https://vimeo.com/745444576'">
        </app-video-player>
      </div>
    </div>
  </section>

  <!-- SEZIONE IMMAGINI -->
  <section class="flex flex-col w-full h-full mt-12">
    <h1 class="text-4xl md:text-5xl text-center font-bold mx-auto">Di cosa parleremo?</h1>
    <p class="text-center text-lg mx-auto px-2 sm:px-0 max-w-4xl mt-8">
      Obbiettivo 250 è un incontro live dove Top Trading Academy si mette a nudo e svela come è possibile
      realizzare il 134% di profitto con un capitale di 50.000 euro, realizzando oltre <span class="font-bold">67.000 euro di guadagno</span>
      in soli sei mesi e puntando a realizzare il 250% in 12 mesi.
    </p>
    <div class="flex flex-col m-auto w-full h-full gap-8 py-12 px-8 text-lg">
      <div class="flex flex-col md:flex-row-reverse mx-auto items-center space-y-4">
        <div class="m-auto">
          <ul class="list-disc lg:translate-x-4">
            <li>Introduzione</li>
            <li>Visioniamo i risultati di un nostro studente ora trader professionista</li>
            <li>Risultati dopo 6 mesi</li>
            <li>Cos'è il servizio Ammiratore</li>
          </ul>
        </div>
        <div class="flex flex-col w-full h-full">
          <picture>
            <source srcset="../../../../assets/images/obbiettivo-250/equity_ammiratore.jpg" type="image/jpg">
            <img class="md:max-w-lg" src="../../../../assets/images/obbiettivo-250/equity_ammiratore.jpg"
                 alt="Equity curve ammiratore" width="2500" height="1235"
                 title="Equity curve ammiratore">
          </picture>
          <p class="text-xs md:text-sm text-gray-300 text-left pb-1">Equity Curve Ammiratore</p>
        </div>
      </div>
      <div class="flex flex-col m-auto w-full h-full gap-8 py-12 lg:px-8 text-lg">
        <div
          class="relative flex flex-col md:flex-row max-w-5xl items-center lg:space-x-8 space-y-4 m-auto">
          <div class="hidden lg:block z-0 absolute top-8 -left-36">
            <h1 class="outlined-text text-8xl sm:text-[10rem] lg:text-[20rem]">
              LIVE
            </h1>
          </div>
          <div class="m-auto z-10">
            <ul class="list-disc">
              <li>Perché è difficile essere costanti nei risultati con l’analisi tecnica</li>
              <li>Perché i PDN (pezzi da Novanta) dominano il mercato e ne influenzano l’andamento</li>
              <li>Prezzo manipolato dai PDN per derubare i traders ignari</li>
              <li>Perché è valida l’analisi ciclico volumetrica e permette risultati</li>
            </ul>
          </div>
          <div class="relative flex flex-col w-full m-auto lg:w-2/3 h-full">
            <app-last-signal-card
              class="z-10"
              [fakeLive]="true"
              [PL]="pl"
              [signal]="signal">
            </app-last-signal-card>
            <p class="text-xs md:text-sm text-gray-300 text-right pb-1 mt-1">Servizio Ammiratore (esempio)</p>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row-reverse mx-auto items-center text-lg">
        <div class="m-auto">
          <ul class="list-disc lg:translate-x-8">
            <li>Quali sono le figure presenti sul mercato</li>
            <li>Come identificarle con i volumi</li>
            <li>Dove ci volgiamo collocare noi</li>
            <li>Quali obbiettivi ci prefissiamo su base annua</li>
          </ul>
        </div>
        <div class="flex flex-col">
          <picture>
            <source srcset="../../../../assets/images/obbiettivo-250/cerchio.webp" type="image/webp">
            <img class="md:max-w-lg filter invert" src="../../../../assets/images/obbiettivo-250/cerchio.jpg"
                 width="750"
                 height="750" alt="indicatore-di-ciclo-tradingonline-analisiciclica"
                 title="indicatore-di-ciclo-tradingonline-analisiciclica">
          </picture>
          <p class="text-xs md:text-sm text-gray-300 text-left pb-1">Operatori nel Mercato</p>
        </div>
      </div>
      <div class="flex flex-col mx-auto">
        <div
          class="flex flex-col md:flex-row max-w-5xl space-x-4 items-center md:items-end mx-auto">
          <div class="m-auto">
            <ul class="list-disc">
              <li>Come può un nostro studente raggiungere gli stessi obbiettivi?</li>
              <li>Formazione consigliata</li>
              <li>Lezioni private</li>
              <li>Trading live con un maestro</li>
            </ul>
          </div>
          <div class="flex flex-col">
            <picture>
              <source srcset="../../../../assets/images/obbiettivo-250/professionali.webp" type="image/webp">
              <img class="md:max-w-lg filter invert" src="../../../../assets/images/obbiettivo-250/professionali.jpg"
                   width="750" height="750" alt="indicatore-di-ciclo-tradingonline-analisiciclica"
                   title="indicatore-di-ciclo-tradingonline-analisiciclica">
            </picture>
            <p class="text-xs md:text-sm text-gray-300 text-right pb-1">Figura dei Trader Professionali</p>
          </div>
        </div>
      </div>
    </div>
    <h2 class="text-2xl text-white px-4 pb-8 mx-auto max-w-4xl text-center py-4">
      ...Molto altro ancora. Non mancare, è un’opportunità unica. <br>
      Alla fine della live consegneremo un incredibile regalo!
    </h2>
  </section>
  <!-- SEZIONE COUNTDOWN -->
  <section class="flex flex-col bg-redCustom  text-black">
    <div class="flex flex-wrap mx-auto">
      <h3 class="flex flex-wrap mt-8 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 pb-2 md:pb-0 md:mr-4 h-8 w-8" fill="none"
             viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
        </svg>
        <span class="text-base md:text-2xl font-semibold">{{ nextWebinarDateString }}</span>
      </h3>
    </div>
    <button [routerLink]="['/webinar', 'obiettivo-250', request.campaign.campaignId, request.dayOfCycle]"
            fragment='form-iscrizione-webinar' routerLinkActive="router-link-active"
            class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-4 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
      <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">Prenota il tuo posto gratis</span>
    </button>
    <div class="flex flex-col m-auto pb-8 text-center">
      <h1 class="mx-auto sm:text-lg mt-2 tracking font-semibold capitalize">
        I posti sono solo 1000, non perdere altro tempo
      </h1>
      <h2 class="mx-auto text-xl sm:text-2xl mt-2 tracking font-bold">
        {{ nextWebinarDateCountDown | countdown | async }}
      </h2>
    </div>
  </section>
  <!-- SEZIONE TESTIMONIAL -->
  <section class="flex flex-col py-12">
    <h1 class="mx-auto text-2xl md:text-4xl text-center font-semibold pb-4">Cosa dicono di noi gli studenti</h1>
    <div class="w-full flex flex-wrap px-4 mx-auto">
      <div class="w-full m-auto px-4 grid grid-cols-1 sm:grid-cols-2 gap-4 gap-x-8">
        <div class="w-full mx-auto">
          <app-video-player
            [facadeId]="'obiettivo-250-preview-testimonial-2'"
            [vimeoLink]="'https://player.vimeo.com/video/616072925'">
          </app-video-player>
        </div>
        <div class="w-full mx-auto">
          <app-video-player
            [facadeId]="'obiettivo-250-preview-testimonial-3'"
            [vimeoLink]="'https://player.vimeo.com/video/616073193'">
          </app-video-player>
        </div>
      </div>
    </div>
  </section>
  <!-- SEZIONE FORM -->
  <section class="flex flex-col bg-customDark text-white">
    <div class="flex flex-wrap mx-auto">
      <h1 class="flex flex-wrap mt-8 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 pb-2 md:pb-0 md:mr-4 h-8 w-8" fill="none"
             viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
        </svg>
        <span class="text-base md:text-2xl font-semibold">{{ nextWebinarDateString }}</span>
      </h1>
    </div>
    <div class="flex flex-col mx-auto py-8"  id="form-iscrizione-webinar">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="flex flex-col gap-y-4">
          <div class="flex flex-col mx-auto w-80 md:w-96">
            <label for="name" class="label-std-left-validated">Nome</label>
            <input type="text" id="name" name="name" [(ngModel)]="request.name"
                   formControlName="name"
                   class="input-std-dark">
            <label *ngIf="m.name.invalid" class="invalid-label">
              Attenzione, Nome obbligatorio.
            </label>
          </div>
          <div class="flex flex-col mx-auto w-80 md:w-96">
            <label for="email" class="label-std-left-validated">Email </label>
            <input type="email" id="email" name="email" [(ngModel)]="request.email"
                   formControlName="email"
                   class="input-std-dark">
            <label *ngIf="m.email.invalid" class="invalid-label">
              Attenzione, Email non valida.
            </label>
          </div>
          <div class="flex flex-col mx-auto w-80 md:w-96">
            <label for="phone" class="label-std-left-validated">Cellulare</label>
            <input type="text" id="phone" name="phone" [(ngModel)]="lead.phone"
                   formControlName="phone"
                   class="input-std-dark">
            <label *ngIf="m.phone.invalid" class="invalid-label">
              Attenzione, telefono non valido.
            </label>
          </div>
          <!--PRIVACY-->
          <div class="col-span-6 sm:col-span-6 text-gray-300 m-auto text-xs text-left mt-2 space-y-4">
            <!--privacyTrattamento-->
            <div class="flex flex-row space-x-4">
              <div class="flex flex-col">
                <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                  Acconsento al trattamento dei dati secondo quanto definito dalla vostra
                  Informativa sulla Privacy esposta in questo sito.
                </p>
                <p *ngIf="m.privacyTrattamento.invalid" [@fade]="m.privacyTrattamento.invalid"
                   class="invalid-label">
                  Consenso obbligatorio
                </p>
              </div>
              <div class="flex flex-col justify-center">
                <p>Si</p>
                <label for="privacyTrattamento-true"></label>
                <input id="privacyTrattamento-true" [value]="true"
                       class="radio-redCustom"
                       formControlName="privacyTrattamento"
                       type="radio">
              </div>
              <div class="flex flex-col justify-center">
                <p>No</p>
                <label for="privacyTrattamento-false"></label>
                <input id="privacyTrattamento-false" [value]="false"
                       class="radio-redCustom"
                       formControlName="privacyTrattamento"
                       type="radio">
              </div>
            </div>
            <!--privacyContatto-->
            <div class="flex flex-row space-x-4">
              <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                Acconsento ad essere contattato da un vostro consulente e ricevere
                tramite sms, e-mail o notifiche Push vostre informazioni
                Pubblicitarie.
              </p>
              <div class="flex flex-col justify-center">
                <p>Si</p>
                <label for="privacyContatto-true"></label>
                <input id="privacyContatto-true" [value]="true" class="radio-redCustom"
                       formControlName="privacyContatto"
                       type="radio">
              </div>
              <div class="flex flex-col justify-center">
                <p>No</p>
                <label for="privacyContatto-false"></label>
                <input id="privacyContatto-false" [value]="false" class="radio-redCustom"
                       formControlName="privacyContatto"
                       type="radio">
              </div>
            </div>
            <!--privacyThirdParty-->
            <div class="flex flex-row space-x-4">
              <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                Acconsento alla comunicazione e/o cessione di dati personali a società
                terze per le loro attività di marketing, quali l’invio di materiale pubblicitario,
                la vendita diretta, il compimento di ricerche di mercato e di comunicazione commerciale.
              </p>
              <div class="flex flex-col justify-center">
                <p>Si</p>
                <label for="privacyThirdParty-true"></label>
                <input id="privacyThirdParty-true" [value]="true" class="radio-redCustom"
                       formControlName="privacyThirdParty"
                       type="radio">
              </div>
              <div class="flex flex-col justify-center">
                <p>No</p>
                <label for="privacyThirdParty-false"></label>
                <input id="privacyThirdParty-false" [value]="false" class="radio-redCustom"
                       formControlName="privacyThirdParty"
                       type="radio">
              </div>
            </div>
          </div>
          <label class="text-black m-auto font-bold">
            Attenzione: tutti i campi sono obbligatori.
          </label>
        </div>
        <div class="flex m-auto justify-center">
          <button type="submit"
                  class="button-std justify-center">
          <span *ngIf="!loading" [ngClass]="{'opacity-50': !form.valid}"
                class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">Prenota il tuo posto gratis</span>
            <span *ngIf="loading" class="text-black text-xl sm:text-2xl lg:text-xl font-bold">Carico</span>
            <app-loading-animation-small *ngIf="loading" class="ml-2"></app-loading-animation-small>
          </button>
        </div>
      </form>
    </div>
    <div class="flex flex-col m-auto py-8 text-center">
      <h1 class="mx-auto sm:text-lg mt-2 tracking font-semibold capitalize">
        I posti sono solo 1000, non perdere altro tempo
      </h1>
      <h2 class="mx-auto text-xl sm:text-2xl mt-2 tracking font-bold">
        {{ nextWebinarDateCountDown | countdown | async }}
      </h2>
    </div>
  </section>
  <p class="text-gray-400 text-xs mx-auto py-12 text-center mt-96">
    DISCLAIMER: Questo sito non è parte di Facebook o Facebook Inc. Inoltre questo sito non è autorizzato da Facebook in alcun modo. Facebook è un marchio di Facebook Inc.
  </p>
</div>