import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'landing-pages';
  public url: any;

  constructor(private router: Router) {
    router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd){
        gtag('config','AW-661998525',{'page_path' : y.url});
        fbq('track', 'PageView');
        this.url = y.url;
      }
    })
  }
}
