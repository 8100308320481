<div class="flex justify-center min-h-screen w-full h-full sm:block sm:p-0 bg-customDark bg-cover bg-no-repeat text-white">
  <div class="container mx-auto pb-8 px-8">
    <div class="flex flex-col text-base pt-24 text-white space-y-2">
      <h1 class="text-3xl font-bold text-center py-8">Cookie Policy di:</h1>
      <ul class="list-disc">
        <li class="text-xl ml-12">top-tradingacademy.it</li>
        <li class="text-xl ml-12">top-tradingacademy.eu</li>
        <li class="text-xl ml-12">tradingacademy.biz</li>
      </ul>
      <div class="flex m-auto mt-8 flex-row font-bold pb-12">
        <a class="group" (click)="back()">
          <h3 class="mx-auto cursor-pointer group-hover:text-redCustom transition duration-300 ease-in-out">
            <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-redCustom transition duration-300 ease-in-out inline-flex pb-1 h-5 w-5" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            Torna indietro
          </h3>
        </a>
      </div>
      <p>
        Questo documento contiene informazioni in merito alle tecnologie che consentono a questa Applicazione di raggiungere gli scopi descritti di seguito. Tali tecnologie permettono al Titolare di
        raccogliere e salvare informazioni (per esempio tramite l’utilizzo di Cookie) o di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell’Utente quando quest’ultimo
        interagisce con
        questa Applicazione.
      </p>
      <p>
        Per semplicità, in questo documento tali tecnologie sono sinteticamente definite “Strumenti di Tracciamento”, salvo vi sia ragione di differenziare.
        Per esempio, sebbene i Cookie possano essere usati in browser sia web sia mobili, sarebbe fuori luogo parlare di Cookie nel contesto di applicazioni per dispositivi mobili, dal momento che si
        tratta
        di Strumenti di Tracciamento che richiedono la presenza di un browser. Per questo motivo, all’interno di questo documento il temine Cookie è utilizzato solo per indicare in modo specifico quel
        particolare tipo di Strumento di Tracciamento.
      </p>
      <p>
        Alcune delle finalità per le quali vengono impiegati Strumenti di Tracciamento potrebbero, inoltre richiedere il consenso dell’Utente. Se viene prestato il consenso, esso può essere revocato
        liberamente in qualsiasi momento seguendo le istruzioni contenute in questo documento.
      </p>
      <p>
        Questa Applicazione utilizza Strumenti di Tracciamento gestiti direttamente dal Titolare (comunemente detti Strumenti di Tracciamento “di prima parte”) e Strumenti di Tracciamento che
        abilitano
        servizi forniti da terzi (comunemente detti Strumenti di Tracciamento “di terza parte”). Se non diversamente specificato all’interno di questo documento, tali terzi hanno accesso ai rispettivi
        Strumenti di Tracciamento.
        Durata e scadenza dei Cookie e degli altri Strumenti di Tracciamento simili possono variare a seconda di quanto impostato dal Titolare o da ciascun fornitore terzo. Alcuni di essi scadono al
        termine
        della sessione di navigazione dell’Utente.
      </p>
      <p>
        In aggiunta a quanto specificato nella descrizione di ciascuna delle categorie di seguito riportate, gli Utenti possono ottenere informazioni più dettagliate ed aggiornate sulla durata, così
        come
        qualsiasi altra informazione rilevante - quale la presenza di altri Strumenti di Tracciamento - nelle privacy policy dei rispettivi fornitori terzi (tramite i link messi a disposizione) o
        contattando
        il Titolare.
      </p>
    </div>
  </div>
</div>