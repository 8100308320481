<div class="flex flex-col justify-center text-white min-h-screen w-full h-full bg-customDark scroll-smooth">
  <!-- SEZIONE HERO -->
  <section class="flex pt-12 m-auto w-full xl:w-5/6 4k:w-2/3 bg-cover bg-no-repeat items-center flex-col xl:gap-x-8">
    <!-- TESTO -->
    <div class="flex m-auto flex-col lg:max-w-2/3">
      <div class="flex flex-col">
        <h2 class="flex flex-col text-center px-4 pb-2 text-sm text-gray-300 uppercase font-bold">
          <span class="text-redCustom text-center">video gratuito</span>
        </h2>
        <h1 class="px-4 pb-2 md:pb-4 text-center text-5xl md:text-6xl font-bold">
          <span>Stanco di perdere soldi?</span><br>
        </h1>
        <h2 class="px-4 pb-4 mt-4 md:pb-8 text-center text-2xl md:text-3xl font-bold">
          <span>L'utilizzo professionale dei volumi nelle tue decisioni operative</span>
        </h2>
      </div>
      <h2 class="py-4 my-2 px-4 mx-auto  text-xl max-w-4xl text-left border-l-2 text-gray-300 border-gray-300 ml-8 md:ml-16">
        Guarda un’operazione del vivo, le testimonianze ed un’estratto della registrazione della prima lezione dal vivo di ”Volumaster”, dove Marco Magnani introduce l’utilizzo professionale dei
        volumi nel trading online.
      </h2>
    </div>
    <div class="flex flex-col mx-auto mt-12 text-center">
      <h1 class="text-4xl md:text-3xl font-bold mx-auto">Anni di studio e ricerca</h1>
      <h2 class="text-lg px-4 text-gray-300 pt-2 mt-4 pb-4 mx-auto md:max-w-2xl">
        Hanno portato <span class="font-bold">Marco Magnani</span> ad una profonda conoscenza del <span class="font-bold">Volume Profile</span> che utilizza per analizzare il movimento dei prezzi,
        capendo cosa fano i <span class="font-bold">PDN (Pezzi da Novanta)</span> ed i Trader Professionali tramite i
        flussi di denaro che entrano ed escono dal mercato.
      </h2>
      <div class="flex flex-col bg-redCustom rounded-lg mx-2 md:mx-4 my-8 px-4 shadow-dark text-black">
        <button [routerLink]="['/webinar', 'volumaster-video', request.campaign.campaignId, request.dayOfCycle]" fragment='form-iscrizione-video'
          class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
          <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold">Guarda il Video gratis</span>
        </button>
        <div class="flex flex-col m-auto pb-8 text-center">
          <h3 class="mx-auto text-xl mt-2 tracking font-semibold capitalize">I posti sono limitati, non perdere altro tempo</h3>
        </div>
      </div>
    </div>
  </section>
  <!-- SEZIONE IMMAGINI -->
  <section class="flex flex-col mt-12">
    <h1 class="text-4xl md:text-5xl text-center font-bold mx-auto ">Perchè guardare il video?</h1>
    <div class="flex flex-col mx-auto gap-8 py-12 px-8">
      <div class="flex flex-col md:flex-row-reverse mx-auto items-center">
        <h2 class="text-2xl px-4 ml-8 text-gray-300 pt-2 pb-4 m-auto  md:max-w-md text-center ">
          Potrai capire attraverso il Volume Profile quando stanno operando i Pezzi da Novanta per poterli copiare.
          Entra nel mondo del Trading Ciclico Volumetrico.
        </h2>
        <div class="flex flex-col">
          <picture>
            <source srcset="../../../../assets/images/webinar/vm.webp" type="image/webp">
            <img class="md:max-w-lg" src="../../../../assets/images/webinar/vm.jpg" width="750" height="750" alt="indicatore-di-ciclo-tradingonline-analisiciclica"
              title="indicatore-di-ciclo-tradingonline-analisiciclica">
          </picture>
          <p class="text-xs md:text-sm text-gray-300 text-left pb-1">Indicatore Volume Profile</p>
        </div>
      </div>
      <div class="flex flex-col mx-auto">
        <div class="flex flex-col md:flex-row max-w-5xl items-center md:items-end mx-auto slide-in-blurred-right">
          <h2 class="text-2xl text-gray-300 px-4 mr-8 pb-4 m-auto  max-w-4xl text-center py-4 ">
            I cicli di borsa sono un alleato formidabile dei volumi per individuare i massimi ed i minimi del prezzo.
          </h2>
          <div class="flex flex-col">
            <img class="md:max-w-lg" src="../../../../assets/images/webinar/1.jpg" width="750" height="750" alt="indicatore-di-ciclo-tradingonline-analisiciclica"
              title="indicatore-di-ciclo-tradingonline-analisiciclica">
            <p class="text-xs md:text-sm text-gray-300 text-right pb-1">Indicatore di Ciclo</p>
          </div>
        </div>
      </div>
    </div>
    <h2 class="text-2xl text-ivoryWhite px-4 pb-8 mx-auto max-w-4xl text-center py-4 ">
      Molto altro ancora. Non mancare, è un’opportunità unica.
    </h2>
  </section>
  
  <!-- <section class="flex flex-col bg-redCustom  text-black">
    <button [routerLink]="['/webinar', 'volumaster-video', request.campaign.campaignId, request.dayOfCycle]" fragment='form-iscrizione-video' routerLinkActive="router-link-active"
      class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
      <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">Guarda il Video gratis</span>
    </button>
    <div class="flex flex-col m-auto pb-8 text-center">
      <h3 class="mx-auto text-xl mt-2 tracking">I posti sono limitati, non perdere altro tempo</h3>
    </div>
  </section> -->
  <!-- SEZIONE TESTIMONIAL STUDENTI -->
  <section class="flex flex-col py-12">
    <h1 class="mx-auto text-2xl md:text-4xl text-center font-semibold pb-4">Cosa dicono di noi gli studenti</h1>
    <div class="flex flex-wrap px-4 mx-auto">
      <div class="mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 gap-x-8">
        <div class="w-full mx-auto">
          <!-- <h1 class="mx-auto text-xl font-semibold py-2">Sergio</h1> -->
          <iframe loading="lazy" width="360" height="202" src="https://player.vimeo.com/video/534797770" title="YouTube video player" frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
        <div class="w-full mx-auto">
          <!-- <h1 class="mx-auto text-xl font-semibold py-2">Stefano</h1> -->
          <iframe loading="lazy" width="360" height="202" src="https://player.vimeo.com/video/534797618" title="YouTube video player" frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
        <div class="w-full mx-auto">
          <!-- <h1 class="mx-auto text-xl font-semibold py-2">Tierry</h1> -->
          <iframe loading="lazy" width="360" height="202" src="https://player.vimeo.com/video/534797891" title="YouTube video player" frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
      </div>
    </div>
  </section>
  <!-- SEZIONE FORM -->
  <section class="flex flex-col bg-redCustom  text-black">
    <div class="flex flex-col mx-auto py-8">
      <form (ngSubmit)="onSubmit()" id="form-iscrizione-video">
        <div class="flex flex-col gap-y-4">
          <div class="flex flex-col mx-auto w-80 md:w-96">
            <label for="name" class="block text-sm font-bold">Nome <span *ngIf="!isNameValid">*</span></label>
            <input type="text" id="name" name="name" [(ngModel)]="request.name" (change)="validateName(request.name)"
              class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
            <label *ngIf="!isNameValid" class="text-black font-bold pt-2" for="phoneError">Attenzione, Nome obbligatorio.</label>
          </div>
          <div class="flex flex-col mx-auto w-80 md:w-96">
            <label for="email" class="block text-sm font-bold">Email <span *ngIf="!isEmailValid">*</span></label>
            <input type="email" id="email" name="email" [(ngModel)]="request.email" (change)="validateEmail(request.email)"
              class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
            <label *ngIf="!isEmailValid" class="text-black font-bold pt-2" for="phoneError">Attenzione, Email non valida.</label>
          </div>
          <div class="flex flex-col mx-auto w-80 md:w-96">
            <label for="phone" class="block text-sm font-bold">Cellulare <span *ngIf="!isPhoneValid">*</span></label>
            <input type="text" id="phone" name="phone" [(ngModel)]="phone"
              class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
            <label *ngIf="!isPhoneValid" class="text-black font-bold pt-2" for="phoneError">Attenzione, telefono non valido.</label>
          </div>
          <label class="text-black m-auto font-bold" for="Information">Attenzione: tutti i campi sono obbligatori.</label>
        </div>
        <button type="submit"
          class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
          <span *ngIf="!loading" class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">Guarda il video gratis</span>
          <span *ngIf="loading" class="text-black text-xl sm:text-2xl lg:text-xl font-bold">Carico...</span>
          <div *ngIf="loading" class="ml-4 mt-1 animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-black">
          </div>
        </button>
      </form>
    </div>
    <div class="flex flex-col m-auto py-8 text-center">
      <h2 class="mx-auto text-xl mt-2 tracking">I posti sono limitati, non perdere altro tempo</h2>
      <!-- <h3 class="mx-auto text-3xl mt-2 tracking font-bold">{{ nextWebinarDateCountDown | countdown | async }}</h3> -->
    </div>
  </section>
</div>