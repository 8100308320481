import { Component, Input, OnInit } from '@angular/core';
import { SignalAmmiratore } from "../../helpers/models/signalAmmiratore.model";
import { Operation } from "../../helpers/enums/operation.enum";
import { fade } from "../../helpers/animations/fade.animation";

@Component({
  selector: 'app-last-signal-card',
  templateUrl: './last-signal-card.component.html',
  styleUrls: ['./last-signal-card.component.scss'],
  animations:[fade]
})
export class LastSignalCardComponent implements OnInit {

  @Input() signal: SignalAmmiratore
  @Input() PL: number
  @Input() fakeLive: boolean

  operation = Operation

  constructor() { }

  ngOnInit(): void {
  }

}
