<div class="flex justify-center min-h-screen w-full h-full sm:block sm:p-0 bg-customDark bg-cover bg-no-repeat text-white">
  <div class="container mx-auto pb-8 px-8">
    <div class="flex flex-col text-center pt-24">
      <h1 class="text-3xl text-center font-bold">
        Disclaimer
      </h1>
      <p class="text-left pt-4 font-semibold">
        Questo è un sito di proprietà di TTgames s.r.l. con esclusiva finalità educativa e didattica, il suo contenuto non costituisce alcuna forma di consulenza o “raccomandazione di investimento” o
        “incentivo all’investimento” né in forma esplicita o implicita, come delineato con precisione nei termini e condizioni, che devono essere “attentamente lette ed obbligatoriamente accettare”
        prima di poter accedere ai servizi. Tutti i servizi disponibili online, all’interno di questo sito, fanno parte di percorsi formativi ed educativi che illustrano come operare con il “trading”
        esclusivamente per scopi didattici e formativi. Lo scopo di questo sito è diffondere la cultura che il trading e gli investimenti sono attività rischiose, non adatte a tutti, che vanno
        affrontate con conoscenza e preparazione. La formazione e l’esercizio sono l’obbiettivo primario degli autori del sito. Qualsiasi concetto, nozione, parere o tecnica espressi all’interno delle
        dirette sono semplici pareri personali degli autori, di carattere generale, non hanno lo scopo di prestare consigli operativi personalizzati, di acquisto e/o vendita, ne raccomandazioni
        personalizzate riguardo una o più operazioni relative ad un determinato strumento finanziario. Nessuna opinione espressa riguardante investimenti o strategie di investimento può pertanto
        considerarsi adeguata alle caratteristiche di una specifica persona in merito alla sua conoscenza ed esperienza del trading online ed alla sua situazione finanziaria.
      </p>
    </div>
    <div class="flex m-auto mt-8 flex-row font-bold">
        <a class="group" (click)="back()">
          <h3 class="mx-auto cursor-pointer group-hover:text-redCustom transition duration-300 ease-in-out">
            <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-redCustom transition duration-300 ease-in-out inline-flex pb-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            Torna indietro
          </h3>
        </a>
      </div>
  </div>
</div>