import { Component, OnInit, Renderer2 } from '@angular/core';
import { AddContactOptions } from "../../../helpers/models/addContact.getResponse.model";
import { SignalAmmiratore } from "../../../helpers/models/signalAmmiratore.model";
import { Operation } from "../../../helpers/enums/operation.enum";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Lead } from "../../../helpers/models/lead.model";
import { ActivatedRoute, Router } from "@angular/router";
import { GetResponseService } from "../../../helpers/services/getResponse.service";
import { LeadsService } from "../../../helpers/services/leads.service";
import { ToastrService } from "ngx-toastr";
import { SEOService } from "../../../helpers/services/seo.service";
import { EmailPattern } from "../../../helpers/patterns/validators.patterns";
import { FacebookPixelsService } from "../../../helpers/services/facebookPixels.services";
import { fade } from "../../../helpers/animations/fade.animation";
import { environment } from "../../../../environments/environment";

const getResponseValues: AddContactOptions = {
  name: '',
  campaign: {
    campaignId: ''
  }, // Taken from URL (Route params)
  email: '',
  dayOfCycle: '0',
  ipAddress: '0.0.0.0',
  customFieldValues: []
}

@Component({
  selector: 'app-obiettivo-duemezzo',
  templateUrl: './obiettivo-duemezzo.component.html',
  styleUrls: ['./obiettivo-duemezzo.component.scss'],
  animations: [fade]
})
export class ObiettivoDuemezzoComponent implements OnInit {

  signal: SignalAmmiratore = {
    positionId: "390983210",
    operation: Operation.Close,
    symbol: "Ger40",
    sendDate: Date.now() / 1000,
    side: "BUY",
    contracts: "10.00",
    price: "13253.64",
    stopLoss: "0",
    takeProfit: "0",
    profitAndLoss: "275.23",
  }

  lead: Lead = {
    leadId: 0,
    fullName: '',
    email: '',
    phone: '',
    whichCourses: '',
    privacyTrattamento: true,
    privacyContatto: true,
    privacyThirdParty: true,
  }

  request: AddContactOptions = getResponseValues


  pl: number = 67073.96
  form: FormGroup

  loading: boolean = false;

  nextWebinarDateString: string = 'Lunedì 26 Settembre 2022 · Ore 21:00'
  nextWebinarDateCountDown: string = '2022-09-26T21:00:00.00Z'

  constructor(
    private route: ActivatedRoute,
    private renderer2: Renderer2,
    private facebookPixelService: FacebookPixelsService,
    private seo: SEOService,
    private router: Router,
    private getResponse: GetResponseService,
    private leadService: LeadsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this.route.params.subscribe(params => {
      this.request.campaign.campaignId = params['socialId']
      this.request.dayOfCycle = params['dayOfCycle'] || '0'
    })
    this.seo.updateTitle('Obiettivo 250 | Top-Trading Academy')
    this.seo.updateDescription("Obbiettivo 250 è un incontro live dove Top Trading Academy si mette a nudo e svela" +
      "come è possibile realizzare il 134% di profitto con un capitale di 50.000 euro, realizzando oltre 67.000 euro di" +
      "guadagno in soli sei mesi e puntando a realizzare il 250% in 12 mesi.")
    this.form = this.formBuilder.group({
      name: new FormControl(this.request.name, [Validators.required]),
      email: new FormControl(this.request.email, [Validators.required, Validators.pattern(EmailPattern)]),
      phone: new FormControl(this.lead.phone, [Validators.required]),
      privacyTrattamento: new FormControl(this.lead.privacyTrattamento, [Validators.requiredTrue]),
      privacyContatto: new FormControl(this.lead.privacyContatto, [Validators.required]),
      privacyThirdParty: new FormControl(this.lead.privacyThirdParty, [Validators.required])
    })
  }

  async ngOnInit() {
    await this.facebookPixelService.injectScrollTracker(this.renderer2)
  }

  get m() {
    return this.form.controls
  }

  async onSubmit() {
    if (this.form.valid) {
      this.request.customFieldValues.push({
        customFieldId: environment.getResponseCustomFieldId,
        value: [this.lead.phone]
      })
      this.loading = true
      await this.getResponse.addContact(this.request)
        .then(async () => {
          this.toastr.success(`Grazie per esserti iscritto, ci vediamo ${this.nextWebinarDateString}`, "Ottimo!")
          await this.router.navigate(['webinar', 'thank-you', 'obiettivo-250'])
          this.lead.email = this.request.email
          this.lead.fullName = this.request.name
          await this.leadService.createLead(this.lead)
            .catch(() => {
            })
        })
        .catch(() => {
          this.toastr.warning(`Si è verificato un errore, riprova più tardi`, "Ops!")
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.toastr.warning("Tutti i campi sono obbligatori, compilali per continuare", "Attenzione")
    }
  }
}
