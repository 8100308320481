import { Component, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from "rxjs";
import { IAlbum, Lightbox, LIGHTBOX_EVENT, LightboxConfig, LightboxEvent } from "ngx-lightbox";
import { SEOService } from "../../../helpers/services/seo.service";
import { FacebookPixelsService } from "../../../helpers/services/facebookPixels.services";
import { GetResponseService } from "../../../helpers/services/getResponse.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { AddContactOptions } from "../../../helpers/models/addContact.getResponse.model";
import { fade } from "../../../helpers/animations/fade.animation";

const getResponseValues: AddContactOptions = {
  name: '',
  campaign: {
    campaignId: ''
  }, // Taken from URL (Route params)
  email: '',
  dayOfCycle: '0',
  ipAddress: '0.0.0.0',
  customFieldValues: []
}

@Component({
  selector: 'app-previsionali',
  templateUrl: './previsionali.component.html',
  styleUrls: ['./previsionali.component.scss'],
  animations: [fade]
})
export class PrevisionaliComponent implements OnInit {

  timerString: string = ''
  album: Array<IAlbum> = []
  private _subscription: Subscription = new Subscription

  request: AddContactOptions = getResponseValues

  constructor(
    private renderer2: Renderer2,
    private route: ActivatedRoute,
    private seo: SEOService,
    private facebookPixelService: FacebookPixelsService,
    private toastr: ToastrService,
    private _lightbox: Lightbox,
    private _lightboxEvent: LightboxEvent,
    private _lightboxConfig: LightboxConfig
  ) {
    this.getDateInThirtyMinutes()
    for (let i = 1; i <= 6; i++) {
      const src = 'assets/images/webinar/previsionali/' + 'previsionali_immagine_' + i + '.png';
      const thumb = '';
      const image = {
        src: src,
        thumb: thumb,
        downloadUrl: ''
      };

      this.album.push(image);
    }

    // set default config
    this._lightboxConfig.fadeDuration = 1;

    this.seo.updateTitle('Servizio Previsionali | Top-Trading Academy')
    this.seo.updateOgLocale()
    this.seo.updateOgType('article')
    this.seo.updateOgImage('https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/anteprima-link-webinar.jpg')
    this.seo.updateDescription(`Il servizio per soli 15 euro al mese comprende previsioni professionali e sempre aggiornate effettuate dal nostro Analista Marco Magnani sui seguenti mercati: Bitcoin, Dax, S&P500, FTSEMIB, EUR_USD.`)
    this.route.params.subscribe(params => {
      this.request.campaign.campaignId = params['socialId']
      this.request.dayOfCycle = params['dayOfCycle'] || '0'
    })
  }

  async ngOnInit() {
    await this.facebookPixelService.injectScrollTracker(this.renderer2)
  }

  /**
   * Crea timer di 30 minuti
   */
  getDateInThirtyMinutes (){
    let today = new Date();
    let todayInThirtyMinutes = new Date(today.getTime() + 30 * 60000);
    let yyyy = todayInThirtyMinutes.getFullYear();
    let MM = String(todayInThirtyMinutes.getMonth() + 1).padStart(2, '0'); //January is 0!
    let dd = String(todayInThirtyMinutes.getDate()).padStart(2, '0');
    let hh = String(todayInThirtyMinutes.getHours() - 2).padStart(2, '0');
    let mm = String(todayInThirtyMinutes.getMinutes()).padStart(2, '0');

    this.timerString = `${yyyy}-${MM}-${dd}T${hh}:${mm}:00.00Z`
  }

  /**
   * Redirect allo shop sulla pagina Previsionali
   */
  async redirectToShop() {
    await this.facebookPixelService.injectAddToCartTracker(this.renderer2, 15)
    window.location.href = 'https://top-tradingacademy.com/shop/product/598/previsionali'
  }

  /**
   * Apre lightbox con immagine
   * @param index Indice immagine da aprire
   * @param caption Descrizione immagine
   */
  open(index: number, caption?: string): void {
    this._subscription = this._lightboxEvent.lightboxEvent$
      .subscribe(event => this._onReceivedEvent(event));

    // override the default config
    this.album[index].caption = caption;
    this._lightbox.open(this.album, index, {
      fadeDuration: 0.25,
      resizeDuration: 0.25,
      wrapAround: true,
      showImageNumberLabel: false,
      centerVertically: true,
      alwaysShowNavOnTouchDevices: false,
      showZoom: true,
      disableKeyboardNav: true,
    });
  }

  /**
   * Toglie sottoscrizione a Subscription lightbox
   * @param event Evento di chiusura lightbox
   * @private
   */
  private _onReceivedEvent(event: any): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this._subscription.unsubscribe();
    }
  }
}
