<div class="flex flex-col justify-center text-white min-h-screen w-full h-full bg-customDark bg-cover bg-no-repeat">
  <!-- SEZIONE HERO -->
  <section class="flex pt-12 m-auto w-full xl:w-5/6 4k:w-2/3 bg-cover bg-no-repeat items-center flex-row gap-x-8">
    <!-- TESTO -->
    <div class="flex m-auto flex-col lg:max-w-1/2">
      <div class="flex flex-col">
        <h2 class="flex flex-col md:flex-row px-4 pb-2 text-sm md:text-xs lg:text-sm text-center md:text-left text-gray-300 uppercase font-bold">
          <span class="text-redCustom">webinar gratuito</span>
          <span class="hidden md:block px-2">&#183;</span>
          <span>{{ webinarResponse.nextWebinarDateString }}</span>
        </h2>
        <h1 class="px-4 pb-4 md:pb-8 text-center md:text-left text-5xl md:text-6xl font-bold capitalize">
          <span>mi estrategia en un webinar</span>
        </h1>
      </div>
      <h2 class="py-4 my-2 px-4 mx-auto -fast text-xl max-w-4xl text-left border-l-2 text-gray-300 border-gray-300 ml-8 md:ml-16">
        <span class="font-bold text-white">¿Cansado de perder dinero?</span> Apúntate al <span class="font-bold border-b-2 text-white border-dashed border-redCustom">Webinar Gratuito</span>, te
        explicaré un formidable indicador y te desvelaré mi estrategia operativa con la que trabajo cada día.
      </h2>
      <div class="flex flex-col bg-redCustom rounded-lg mx-2 md:mx-8 my-8 px-8 shadow-dark text-black">
        <div class="flex flex-wrap mx-auto">
          <h3 class="flex flex-wrap mt-8 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 pb-2 md:pb-1 md:mr-2 h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <span class="text-base md:text-xl font-semibold">{{ webinarResponse.nextWebinarDateString }}</span>
          </h3>
        </div>
        <button [routerLink]="['/webinar', 'all-inclusive', request.campaign.campaignId, request.dayOfCycle]" fragment='form-iscrizione-webinar' routerLinkActive="router-link-active"
          class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-4 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
          <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">¡Reserva tu plaza de forma gratuita!</span>
        </button>
        <div class="flex flex-col m-auto pb-8 text-center">
          <h3 class="mx-auto text-xl mt-2 tracking font-semibold">Plazas limitadas ¡aprovecha ya!</h3>
          <h3 class="mx-auto text-3xl mt-2 tracking font-bold">{{ webinarResponse.nextWebinarDateCountDown | countdown: true | async }}</h3>
        </div>
      </div>
    </div>
    <div class="hidden md:flex relative m-auto flex-col mx-4 bg-cover bg-no-repeat shadow-dark">
      <div class="flex bg-redCustom m-auto rounded-full w-full h-full">
        <picture>
          <source srcset="../../../../assets/images/webinar/simo_webinar.webp" type="image/webp" width="750" height="750" alt="Simone Rubessi, host del Webinar"
            title="Simone Rubessi, trader e host del Webinar">
          <img class="translate-y-8 rounded-full" src="../../../../assets/images/webinar/simo_webinar.png" width="750" height="750" alt="Simone Rubessi, host del Webinar"
            title="Simone Rubessi, trader e host del Webinar">
        </picture>
      </div>
      <h1 class="text-white mx-auto text-3xl px-12 pt-8 font-semibold">Simone Rubessi</h1>
      <p class="mx-auto text-gray-300">Trader</p>
    </div>
  </section>

  <!-- SEZIONE IMMAGINI -->
  <section class="flex flex-col mt-12">
    <h1 class="text-4xl md:text-5xl text-center font-bold mx-auto ">¿Qué aprenderás en el webinar?</h1>
    <div class="flex flex-col mx-auto gap-8 py-12 px-8">
      <div class="flex flex-col md:flex-row-reverse mx-auto  items-center">
        <h2 class="text-2xl px-4 ml-8 text-gray-300 pt-2 pb-4 m-auto -fast md:max-w-md text-center ">
          Aprenderás a identificar los Ciclos bursátiles, la mejor estrategia para identificar máximos y mínimos del mercado.
        </h2>
        <div class="flex flex-col">
          <img class="md:max-w-lg" src="../../../../assets/images/webinar/1.jpg" width="750" height="750" alt="indicatore-di-ciclo-tradingonline-analisiciclica"
            title="indicatore-di-ciclo-tradingonline-analisiciclica">
          <p class="text-xs md:text-sm text-gray-300 text-left pb-1">Indicador de ciclo</p>
        </div>
      </div>
      <div class="flex flex-col mx-auto">
        <div class="flex flex-col md:flex-row max-w-5xl items-center md:items-end mx-auto slide-in-blurred-right">
          <h2 class="text-2xl text-gray-300 px-4 mr-8 pb-4 m-auto -fast max-w-4xl text-center py-4 ">
            Aprenderás a utilizar el siguiente indicador (en verde) que te permitirá identificar los puntos de inversión de los precios con una facilidad sin igual. Exactamente como se muestra en la
            imagen.
          </h2>
          <div class="flex flex-col">
            <img class="md:max-w-lg" src="{{ imageToDisplay }}" width="750" height="750" alt="indicatore-di-ciclo-tradingonline-analisiciclica"
              title="indicatore-di-ciclo-tradingonline-analisiciclica">
            <p class="text-xs md:text-sm text-gray-300 text-right pb-1">{{ titleToDisplay }}</p>
          </div>
        </div>
      </div>
    </div>
    <h2 class="text-2xl text-ivoryWhite px-4 pb-8 mx-auto -fast max-w-4xl text-center py-4 ">
      Al final te mostraré mi estrategia en todas sus partes, la que me permite vivir del trading y que he construido tras años de estudio.
    </h2>
  </section>
  <!-- SEZIONE COUNTDOWN -->
  <section class="flex flex-col bg-redCustom  text-black">
    <div class="flex flex-wrap mx-auto">
      <h3 class="flex flex-wrap mt-8 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 pb-2 md:pb-0 md:mr-4 h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
        <span class="text-base md:text-2xl font-semibold">{{ webinarResponse.nextWebinarDateString }}</span>
      </h3>
    </div>
    <button [routerLink]="['/webinar', 'all-inclusive', request.campaign.campaignId, request.dayOfCycle]" fragment='form-iscrizione-webinar' routerLinkActive="router-link-active"
      class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-4 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
      <span class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">¡Reserva tu plaza de forma gratuita!</span>
    </button>
    <div class="flex flex-col m-auto pb-8 text-center">
      <h3 class="mx-auto text-xl mt-2 tracking">Plazas limitadas ¡aprovecha ya!</h3>
      <h3 class="mx-auto text-3xl mt-2 tracking font-bold">{{ webinarResponse.nextWebinarDateCountDown | countdown: true | async }}</h3>
    </div>
  </section>
  <!-- SEZIONE TESTIMONIAL VIDEO -->
  <section class="flex flex-col py-12">
    <h1 class="mx-auto text-4xl font-semibold pb-4">Lo que se dice de nosotros</h1>
    <div class="flex flex-wrap px-4 mx-auto">
      <div class="mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 gap-x-8">
        <div class="w-full mx-auto">
          <h1 class="mx-auto text-xl font-semibold py-2">Alessio</h1>
          <iframe loading="lazy" width="360" height="202" src="https://player.vimeo.com/video/563670378" title="YouTube video player" frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
        <div class="w-full mx-auto">
          <h1 class="mx-auto text-xl font-semibold py-2">Stefano</h1>
          <iframe loading="lazy" width="360" height="202" src="https://player.vimeo.com/video/563670665" title="YouTube video player" frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
        <div class="w-full mx-auto">
          <h1 class="mx-auto text-xl font-semibold py-2">Tierry</h1>
          <iframe loading="lazy" width="360" height="202" src="https://player.vimeo.com/video/563671427" title="YouTube video player" frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
      </div>
    </div>
  </section>
  <!-- SEZIONE FORM -->
  <section class="flex flex-col bg-redCustom  text-black">
    <div class="flex flex-wrap mx-auto">
      <h3 class="flex flex-wrap mt-8 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 pb-2 md:pb-0 md:mr-4 h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
        <span class="text-base md:text-2xl font-semibold">{{ webinarResponse.nextWebinarDateString }}</span>
      </h3>
    </div>
    <div class="flex flex-col mx-auto py-8">
      <form (ngSubmit)="onSubmit()" id="form-iscrizione-webinar">
        <div class="flex flex-col gap-y-4">
          <div class="flex flex-col mx-auto w-80 md:w-96">
            <label for="name" class="block text-sm font-bold">Nombre <span *ngIf="!isNameValid">*</span></label>
            <input type="text" id="name" name="name" [(ngModel)]="request.name" (change)="validateName(request.name)"
              class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
            <label *ngIf="!isNameValid" class="text-black font-bold pt-2" for="phoneError">Atención, nombre requerido.</label>
          </div>
          <div class="flex flex-col mx-auto w-80 md:w-96">
            <label for="email" class="block text-sm font-bold">Email <span *ngIf="!isEmailValid">*</span></label>
            <input type="email" id="email" name="email" [(ngModel)]="request.email" (change)="validateEmail(request.email)"
              class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
            <label *ngIf="!isEmailValid" class="text-black font-bold pt-2" for="phoneError">Atención: correo electrónico no válido.</label>
          </div>
          <div class="flex flex-col mx-auto w-80 md:w-96">
            <label for="phone" class="block text-sm font-bold">Teléfono <span *ngIf="!isPhoneValid">*</span></label>
            <input type="text" id="phone" name="phone" [(ngModel)]="phone"
              class="bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom-light drop-shadow-md shadow-dark focus:border-redCustom-light hover:bg-customDark transition duration-200 ease-in-out block w-full sm:text-sm rounded-md">
            <label *ngIf="!isPhoneValid" class="text-black font-bold pt-2" for="phoneError">Atención: teléfono inválido.</label>
          </div>
          <label class="text-black m-auto font-bold" for="Information">Atención: todos los campos son obligatorios.</label>
        </div>
        <button type="submit"
          class="flex mx-auto rounded-full disabled:cursor-default bg-greenWebinar shadow-dark border-0 py-2 px-4 md:px-8 mt-8 focus:outline-none hover:bg-greenWebinar-dark hover:scale-105 transition duration-300 ease-in-out transform text-md text-center">
          <span *ngIf="!loading" class="text-black text-xl sm:text-2xl lg:text-3xl font-bold capitalize">¡Reserva tu plaza de forma gratuita!</span>
          <span *ngIf="loading" class="text-black text-xl sm:text-2xl lg:text-xl font-bold">Cargando...</span>
          <div *ngIf="loading" class="ml-4 mt-1 animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-black">
          </div>
        </button>
      </form>
    </div>
    <div class="flex flex-col m-auto py-8 text-center">
      <h3 class="mx-auto text-xl mt-2 tracking">Plazas limitadas ¡aprovecha ya!</h3>
      <h3 class="mx-auto text-3xl mt-2 tracking font-bold">{{ webinarResponse.nextWebinarDateCountDown | countdown: true | async }}</h3>
    </div>
  </section>
  <p class="text-gray-400 text-xs mx-auto py-12 text-center mt-96">
    DESCARGO DE RESPONSABILIDAD: Este sitio no es parte de Facebook o Facebook Inc. Además, este sitio no está autorizado por Facebook de ninguna manera. Facebook es una marca comercial de Facebook
    Inc.
  </p>
</div>