<div class="flex justify-center min-h-screen w-full h-full sm:block sm:p-0 bg-customDark bg-cover bg-no-repeat text-white">
  <div class="container mx-auto pb-8 px-8">
    <div class="flex flex-col text-base pt-24 text-white space-y-2">
      <h1 class="text-3xl font-bold py-8">Privacy Policy di:</h1>
      <ul class="list-disc">
        <li class="text-xl ml-12">top-tradingacademy.it</li>
        <li class="text-xl ml-12">top-tradingacademy.eu</li>
        <li class="text-xl ml-12">tradingacademy.biz</li>
      </ul>
      <div class="flex m-auto mt-8 flex-row font-bold pb-12">
        <a class="group" (click)="back()">
          <h3 class="mx-auto cursor-pointer group-hover:text-redCustom transition duration-300 ease-in-out">
            <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-redCustom transition duration-300 ease-in-out inline-flex pb-1 h-5 w-5" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            Torna indietro
          </h3>
        </a>
      </div>
      <p>
        Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.
      </p>

      <p>
        Questo documento può essere stampato utilizzando il comando di stampa presente nelle impostazioni di qualsiasi browser.
      </p>
      <h1 class="text-xl font-bold py-6">
        Titolare del Trattamento dei Dati
      </h1>
      <p class="font-semibold">
        TTgames srl <br>
        Via delle Rose n. 8 <br>
        28922- Verbania (VB) <br>
        Italia
      </p>
      <p>
        Indirizzo email del Titolare: <span class="font-semibold">info@ttgames.it</span>
      </p>
      <h1 class="text-xl font-bold py-6">
        Tipologie di Dati raccolti
      </h1>
      <p>
        Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite terze parti, ci sono: numero di telefono; cognome; email; nome; professione; provincia; stato; nazione; CAP;
        varie
        tipologie di Dati; Cookie; Dati di utilizzo.
      </p>
      <p>
        Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta
        dei dati stessi.
        I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di questa Applicazione.
        Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire
        il
        Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla
        disponibilità del Servizio o sulla sua operatività.
        Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare.
        L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione, ove non diversamente
        precisato, ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.
      </p>
      <p>
        L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questa Applicazione e garantisce di avere il diritto di comunicarli o diffonderli,
        liberando
        il Titolare da qualsiasi responsabilità verso terzi.
      </p>
      <h1 class="text-xl font-bold py-6">
        Modalità e luogo del trattamento dei Dati raccolti
      </h1>
      <h2 class="text-lg font-semibold py-2">
        Modalità di trattamento
      </h2>
      <p>
        Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
        Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in
        alcuni
        casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema)
        ovvero
        soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del
        Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.
      </p>
      <h2 class="text-lg font-semibold py-2">
        Base giuridica del trattamento
      </h2>
      <p>
        Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:
      </p>
      <p>
        l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il
        consenso
        dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento
        di Dati
        Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;
        il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di misure precontrattuali;
        il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;
        il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;
        il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.
        È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge,
        previsto da
        un contratto o necessario per concludere un contratto.
      </p>
      <h2 class="text-lg font-semibold py-2">
        Luogo
      </h2>
      <p>
        I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.
        I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può
        fare
        riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.
      </p>
      <p>
        L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto
        internazionale
        pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.
      </p>

      <p>
        L’Utente può verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione di questo documento relativa ai dettagli sul trattamento di Dati Personali o chiedere
        informazioni
        al Titolare contattandolo agli estremi riportati in apertura.
      </p>
      <h2 class="text-lg font-semibold py-2">
        Periodo di conservazione
      </h2>
      <p>
        I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti. <br>
        Pertanto:
      </p>
      <ul class="list-disc">
        <li>
          I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.
        </li>
        <li>
          I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori
          informazioni in
          merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.
        </li>
      </ul>
      <p>
        Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe
        essere
        obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.
      </p>
      <p>
        Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla
        portabilità
        dei Dati non potranno più essere esercitati.
      </p>
      <h1 class="text-xl font-bold py-6">
        Finalità del Trattamento dei Dati raccolti
      </h1>
      <p>
        I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il Servizio, adempiere agli obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed
        interessi
        (o quelli di Utenti o di terze parti), individuare eventuali attività dolose o fraudolente, nonché per le seguenti finalità: Contattare l'Utente, Registrazione ed autenticazione, Registrazione
        ed
        autenticazione fornite direttamente da questa Applicazione, Statistica, Accesso agli account su servizi terzi, Heat mapping e registrazione sessioni, Gestione dei database di Utenti, Gestione
        dei tag,
        Remarketing e behavioral targeting, Pubblicità, Visualizzazione di contenuti da piattaforme esterne, Hosting ed infrastruttura backend e Gestione contatti e invio di messaggi.
      </p>
      <h1 class="text-xl font-bold py-6">
        Permessi Facebook richiesti da questa Applicazione
      </h1>
      <p>
        Questa Applicazione può richiedere alcuni permessi Facebook che le consentono di eseguire azioni con l’account Facebook dell’Utente e di raccogliere informazioni, inclusi Dati Personali, da
        esso. Questo servizio permette a questa Applicazione di connettersi con l'account dell'Utente sul social network Facebook, fornito da Facebook Inc.
      </p>
      <p>
        Per maggiori informazioni sui permessi che seguono, fai riferimento alla documentazione dei permessi Facebook ed alla privacy policy di Facebook.
      </p>
      <p>
        I permessi richiesti sono i seguenti:
      </p>
      <h2 class="text-lg font-semibold py-2">
        Informazioni di base
      </h2>
      <p>
        Le informazioni di base dell’Utente registrato su Facebook che normalmente includono i seguenti Dati: id, nome, immagine, genere e lingua di localizzazione ed, in alcuni casi gli “Amici” di
        Facebook. Se l'Utente ha reso disponibili pubblicamente Dati ulteriori, gli stessi saranno disponibili.
      </p>
      <h2 class="text-lg font-semibold py-2">
        Accesso ai dati privati
      </h2>
      <p>
        Permette l'accesso ai dati privati dell'Utente e degli amici.
      </p>
    </div>
  </div>
</div>